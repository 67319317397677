import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import RedirectModal from './components/RedirectModal';
import * as modalActions from '../../stores/modal/actions';

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.sinpeRedirectModal,
        sinpeData: state.modal.componentProps,
        ...ownProps
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Object.assign({}, modalActions), dispatch)
});

const SinpeRedirectModal = withRouter(connect(mapStateToProps, mapDispatchToProps)(RedirectModal));

export default SinpeRedirectModal;
