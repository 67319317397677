import React from 'react';
import PropTypes from 'prop-types';

import CurrentPlan from './CurrentPlan';

const FilterPlanComponent = ({ component: Component, ...props }) => {
    /**
     * Verify if component is hidden in current plan
     */
    const hiddenInCurrentPlan = CurrentPlan.hideElement(Component.name);
    return !hiddenInCurrentPlan && <Component {...props} />;
};

FilterPlanComponent.propTypes = {
    component: PropTypes.elementType.isRequired
};

export default FilterPlanComponent;
