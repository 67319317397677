import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Constants from 'js/utils/constants';
import ItemModal from '../../itemModal';
import PlaceOrderModal from '../../placeOrderModal';
import SinpeRedirectModal from '../../sinpeRedirectModal';
import SelectRestaurantModal from '../../selectRestaurantModal';

const CommonModal = ({ actions: { showAppModal } }) => {
    const { open, modalProps, componentType, componentProps } = useSelector((state) => state.modal);
    const { modalFull = true, closeButton = true } = modalProps;

    if (!open) {
        return null;
    }

    let component;
    switch (componentType) {
        case Constants.SINPE_REDIRECT_MODAL:
            component = <SinpeRedirectModal {...componentProps} />;
            break;
        case Constants.PLACE_ORDER_MODAL:
            component = <PlaceOrderModal {...componentProps} />;
            break;
        case Constants.ADD_TO_CART_MODAL:
            component = <ItemModal {...componentProps} />;
            break;
        case Constants.SELECT_RESTAURANT_MODAL:
            component = <SelectRestaurantModal {...componentProps} />;
            break;
        default:
            component = null;
            break;
    }

    return (
        <Modal
            show={open}
            backdrop={'static'}
            onHide={() => showAppModal(false, componentType, {})}
            centered
            className={modalFull ? 'modal-full' : ''}
        >
            <Modal.Header closeButton={closeButton} />
            <Modal.Body className={modalFull ? 'modal-full' : ''}>{component}</Modal.Body>
        </Modal>
    );
};

CommonModal.propTypes = {
    actions: PropTypes.object
};

export default CommonModal;
