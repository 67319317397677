// Detect whether the page is viewed on a mobile device
export default function isMobile() {
    return isAndroid() || isIOS();
}

export function isIOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;
}

export function isAndroid() {
    return navigator.userAgent.match(/Android/i) !== null;
}
