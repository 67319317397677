import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Home from './components/Home';
import * as orderActions from 'js/stores/order/actions';
import * as modalActions from 'js/stores/modal/actions';
import * as headerActions from 'js/stores/header/actions';

export default connect(null, (dispatch) => ({
    actions: bindActionCreators(
        Object.assign({}, orderActions, modalActions, headerActions),
        dispatch
    )
}))(Home);
