import ReactGA from 'react-ga';
import _ from 'lodash';
import { getUserRestaurantName } from './cartUtils';
import { createFunctionWithTimeout, toSlug } from './utilities';
import { getUserSession } from './sessionUtils';

const shouldNotTrack = process.env.NODE_ENV !== 'production';

export const initializeGA = (trackers) => {
    if (shouldNotTrack) return;
    if (!window.ga_init) {
        const trackersList = _.map(trackers, (t) => ({
            trackingId: t.trackingId,
            gaOptions: { name: t.name, userId: getUserSession() }
        }));
        ReactGA.initialize(trackersList, {
            debug: false,
            titleCase: false,
            alwaysSendToDefaultTracker: false
        });

        _.each(trackers, (t) => ReactGA.ga(`${t.name}.require`, 'ec'));

        window.ga_init = true;
    }
};

export const trackPage = (page) => {
    if (shouldNotTrack) return;
    const trackerName = getUserRestaurantName();
    ReactGA.set({ page }, [trackerName]);
    ReactGA.pageview(page, [trackerName]);
};

// workaround to avoid addImpressions being called twice
let addingImpression = false;
export const addImpressions = (items = []) => {
    if (!items.length || addingImpression) {
        return;
    }

    if (shouldNotTrack) return;
    addingImpression = true;
    items.forEach((item, index) => addImpression(item, index));
    send('pageview', {}, () => {
        addingImpression = false;
    });
};

export const addImpression = (item, index) => {
    const impression = {
        id: item.id,
        name: item.name,
        category: item.categories[0] ? item.categories[0].category_id : null,
        price: item.price,
        position: index,
        list: item.categories[0] ? item.categories[0].category_id : null
    };
    ga('ec:addImpression', impression);
};

export const registerItemClick = (item, index) => {
    if (shouldNotTrack) return;
    const product = {
        id: item.id,
        name: item.name,
        category: item.categories[0] ? item.categories[0].category_id : null,
        price: item.price,
        quantity: item.quantity,
        position: index,
        list: item.categories[0] ? item.categories[0].category_id : null
    };
    ga('ec:addProduct', product);
    ga('ec:setAction', 'click', {
        list: item.categories[0] ? item.categories[0].category_id : null
    });
    sendEvent('UX', 'click', 'Items List');
};

export const registerViewItem = (item) => {
    if (shouldNotTrack) return;
    ga('ec:addProduct', {
        id: item.id,
        name: item.name,
        category: item.categories[0] ? item.categories[0].category_id : null,
        price: item.price,
        quantity: item.quantity,
        list: item.categories[0] ? item.categories[0].category_id : null
    });

    ga('ec:setAction', 'detail');

    trackPage(`/menu/${item.category}/${toSlug(item.name)}`);
};

export const registerCartItem = (item, action) => {
    if (shouldNotTrack) return;
    ga('ec:addProduct', {
        id: item.id,
        name: item.name,
        category: item.categories[0] ? item.categories[0].category_id : null,
        price: item.price,
        quantity: item.quantity,
        list: item.categories[0] ? item.categories[0].category_id : null
    });
    ga('ec:setAction', action);
    sendEvent('UX', 'click', action === 'add' ? 'add to cart' : 'remove from cart');
};

// workaround to avoid registerViewCart being called twice
let registeringCartStep = false;
export const registerCheckoutStep = (items = [], step) => {
    if (!items.length || registeringCartStep) {
        return;
    }

    if (shouldNotTrack) return;
    registeringCartStep = true;
    items.forEach((item, index) => {
        ga('ec:addProduct', {
            id: item.id,
            name: item.name,
            category: item.categories[0] ? item.categories[0].category_id : null,
            price: item.price,
            quantity: item.quantity,
            position: index,
            list: item.categories[0] ? item.categories[0].category_id : null
        });
    });

    ga('ec:setAction', 'checkout', {
        step
    });

    if (step === 1) {
        trackPage('/cart');
        registeringCartStep = false;
        return;
    } else if (step === 2) {
        trackPage('/checkout');
        registeringCartStep = false;
        return;
    }

    send('pageview', {}, () => {
        registeringCartStep = false;
    });
};

export const registerCheckoutOption = (option, step) => {
    if (shouldNotTrack) return;
    ga('ec:setAction', 'checkout_option', {
        step,
        option
    });

    sendEvent('enhanced ecommerce', 'checkout_option', `step ${step} - ${option}`);
    send('pageview', {}, () => {
        registeringCartStep = false;
    });
};

export const registerPlaceOrder = (order, callback) => {
    if (shouldNotTrack) {
        if (callback) {
            callback();
        }
        return;
    }
    order.items.forEach((item, index) => {
        ga('ec:addProduct', {
            id: item.id,
            name: item.name,
            category: item.categories[0] ? item.categories[0].category_id : null,
            price: item.price,
            quantity: item.quantity,
            position: index,
            list: item.categories[0] ? item.categories[0].category_id : null
        });
    });

    ga('ec:setAction', 'purchase', {
        id: order.id,
        affiliation: order.affiliation,
        revenue: order.total,
        tax: 0,
        shipping: order.deliveryFee,
        list: 'Category Items List'
    });

    send('pageview', {}, callback);
};

export const sendEvent = (eventCategory, eventAction, eventLabel, callback) => {
    const options = {
        eventCategory,
        eventAction,
        eventLabel
    };
    send('event', options, callback);
};

const send = (hitType, hitOptions, callback) => {
    if (shouldNotTrack) return;
    const trackerName = getUserRestaurantName();
    const options = {
        hitType,
        ...hitOptions
    };

    if (callback) {
        ReactGA.ga(`${trackerName}.send`, options, {
            hitCallback: createFunctionWithTimeout(callback)
        });
    } else {
        ReactGA.ga(`${trackerName}.send`, options);
    }
};

export const ga = (...args) => {
    const trackerName = getUserRestaurantName();
    if (args[2]) {
        ReactGA.ga(`${trackerName}.${args[0]}`, args[1], args[2]);
    } else {
        ReactGA.ga(`${trackerName}.${args[0]}`, args[1]);
    }
};
