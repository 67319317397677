import { createAction } from '@reduxjs/toolkit';
import { cleanShoppingCart, handleUserDefaultForm } from '../shoppingCart/actions';
import { handleOpenShoppingCart } from '../app/actions';
import { showAppModal } from '../modal/actions';
import * as orderUtils from 'js/utils/orderUtils';
import * as cartUtils from 'js/utils/cartUtils';
import * as Tracker from 'js/utils/tracker';
import * as FormUtils from 'js/utils/formUtils';
import Constants from 'js/utils/constants';

export const showPlaceOrderModal = createAction('SHOW_ORDER_MODAL', ({ orderModalOpen, items }) => {
    if (orderModalOpen && items && items.length) {
        Tracker.registerCheckoutStep(items, 2);
    }
    return {
        payload: {
            orderModalOpen
        }
    };
});

export const setContactErrors = createAction('SET_CONTACT_ERRORS');
export const cleanContactErrors = createAction('CLEAN_CONTACT_ERRORS');
export const setActiveKey = createAction('SET_ACTIVE_KEY');

export const loadPlaceOrderModal = ({ orderModalOpen, items }) => {
    return (dispatch) => {
        if (orderModalOpen) {
            dispatch(handleUserDefaultForm({}));
        }
        dispatch(showPlaceOrderModal({ orderModalOpen, items }));
    };
};

export const handlePlaceOrder = (preorderId) => {
    return (dispatch, getState) => {
        const { shoppingCart, companyData } = getState();
        const { selectedRestaurant } = companyData;
        if (!FormUtils.formIsValid(shoppingCart, companyData)) {
            dispatch(handleValidateForm());
            return;
        }
        const order = orderUtils.generateOrder(shoppingCart, companyData, preorderId);
        [Constants.USER.PAYMENT_METHOD, Constants.USER.DELIVERY].forEach((option) =>
            Tracker.registerCheckoutOption(option, shoppingCart[option], 2)
        );
        orderUtils.buildWALink(order, companyData, (waLink) => {
            Tracker.registerPlaceOrder(order, () => {
                Tracker.registerUser(order, companyData.selectedRestaurant.name);
                dispatch(setActiveKey('0'));
                dispatch(handleOpenShoppingCart(false));
                dispatch(showAppModal(false, Constants.PLACE_ORDER_MODAL, {}));
                dispatch(cleanShoppingCart());
                cartUtils.cleanShoppingCartLS(selectedRestaurant.id);
                window.location.href = waLink;
            });
        });
    };
};

export const handleValidateForm = () => {
    return (dispatch, getState) => {
        const { shoppingCart, companyData } = getState();
        const errors = FormUtils.validateUserDefaults(shoppingCart, companyData);
        if (Object.keys(errors).length) {
            dispatch(setContactErrors(errors));
            return;
        }
        dispatch(cleanContactErrors());
    };
};

export const continueToDetailsAction = (open, componentType, componentProps = {}) => {
    return (dispatch) => {
        dispatch(handleOpenShoppingCart(false));
        dispatch(showAppModal(open, componentType, componentProps));
    };
};
