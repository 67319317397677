import { createAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import qs from 'qs';
import appApi from './api';
import * as CartUtils from 'js/utils/cartUtils';
import { getAppSubdomain, parseOpenHours } from 'js/utils/utilities';
import * as cartActions from '../shoppingCart/actions';
import * as appActions from '../app/actions';
import * as modalActions from '../modal/actions';
import * as Tracker from 'js/utils/tracker';
import Constants from 'js/utils/constants';

export const loadCompanyData = createAction('LOAD_COMPANY_DATA');
export const selectDefaultRestaurantAndMenu = createAction('SELECT_DEFAULT_RESTAURANT_AND_MENU');
export const showSelectRestaurantModal = createAction(
    'SHOW_SELECT_RESTAURANT_MODAL',
    ({ selectRestaurantModalOpen }) => {
        return {
            payload: {
                selectRestaurantModalOpen
            }
        };
    }
);

export const loadAppSettings = () => {
    return (dispatch) => {
        const qsParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        let subdomain = getAppSubdomain();

        appApi.getCompanyData(subdomain).then((companyData) => {
            const company = {
                ...companyData,
                restaurants: qsParams.selected_restaurant
                    ? companyData.restaurants
                    : _.filter(companyData.restaurants, (r) => r.status !== 'hidden')
            };
            const { restaurants } = company;
            let userRestaurantId;
            // initialize trackers
            Tracker.initRestaurantTrackers(restaurants);
            // handle scenarios when company has more than 1 restaurant
            if (restaurants.length > 1) {
                if (qsParams.selected_restaurant) {
                    userRestaurantId = qsParams.selected_restaurant;
                } else {
                    // check local storage for previously selected restaurant
                    userRestaurantId = CartUtils.getUserRestaurant();
                }
                // if previously selected restaurant does not exist then unset it and delete it from LS
                if (userRestaurantId && !_.some(restaurants, ['id', userRestaurantId])) {
                    userRestaurantId = null;
                    CartUtils.cleanUpUserRestaurant();
                }

                // if no previously selected restaurant then show modal
                if (!userRestaurantId) {
                    dispatch(
                        modalActions.showAppModal(
                            true,
                            Constants.SELECT_RESTAURANT_MODAL,
                            {},
                            { modalFull: false, closeButton: false }
                        )
                    );
                }
            }
            // if no userRestaurantId then select first restaurant id from company
            userRestaurantId = userRestaurantId || restaurants[0].id;

            dispatch(loadCompanyData(company));
            dispatch(loadSelectedRestaurant(userRestaurantId));
        });
    };
};

export const loadSelectedRestaurant = (restaurantId) => {
    return (dispatch) => {
        let subdomain = getAppSubdomain();
        appApi.getRestaurantData(subdomain, restaurantId).then((restaurantData) => {
            const selectedRestaurant = {
                ...restaurantData,
                open_hours: parseOpenHours(restaurantData)
            };
            const initialCart = CartUtils.getInitialCart(restaurantId);
            const initialUserDefaults = CartUtils.getInitialUserDefaults(selectedRestaurant);
            CartUtils.setUserRestaurantName(selectedRestaurant);
            dispatch(selectDefaultRestaurantAndMenu({ selectedRestaurant }));
            dispatch(cartActions.setInitialCart(initialCart));
            dispatch(cartActions.setInitialUserDefaults(initialUserDefaults));
            dispatch(appActions.appLoading(false));
        });
    };
};

export const switchRestaurant = (restaurant) => {
    return (dispatch, getState) => {
        const {
            companyData: { selectedRestaurant }
        } = getState();
        dispatch(modalActions.showAppModal(false, Constants.SELECT_RESTAURANT_MODAL, {}, {}));
        // if the restaurant selected is different from the current restaurant then load it
        // otherwise just save it locally
        if (restaurant.id !== selectedRestaurant.id) {
            dispatch(appActions.appLoading(true));
            dispatch(loadSelectedRestaurant(restaurant.id));
        }
        // save selected restaurant locally
        CartUtils.setUserRestaurant(restaurant);
    };
};
