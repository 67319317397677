import mixpanel from 'mixpanel-browser';
import Constants from './constants';
import * as SessionUtils from './sessionUtils';

export const initialize = (token) => {
    mixpanel.init(token);
    identifyUser();
};

export const identifyUser = () => {
    const userId = SessionUtils.getUserSession();
    mixpanel.identify(userId);
};

export const setPeople = (data) => {
    mixpanel.people.set({
        $phone: data[Constants.USER.PHONE],
        $first_name: data[Constants.USER.NAME],
        $last_name: '',
        USER_ID: SessionUtils.getUserSession(),
        Address: data[Constants.USER.ADDRESS],
        'Default Payment Method': data[Constants.USER.PAYMENT_METHOD],
        'Default Delivery Option': data[Constants.USER.DELIVERY],
        'Distance from restaurant': data[Constants.USER.ADDRESS_DISTANCE],
        'Address Coordinates': data[Constants.USER.ADDRESS_COORDS],
        'Duration driving from restaurant': data[Constants.USER.ADDRESS_DURATION]
    });
};

export const setPeopleOnce = (data) => {
    mixpanel.people.set_once(data);
};

export const setGroup = (groupKey, groupId) => {
    mixpanel.set_group(groupKey, groupId);
};

export const registerCartItem = (item, action) => {
    const itemAction = action === 'add' ? 'added to cart' : 'removed from cart';
    trackEvent(`Item ${itemAction}`, { ...item });
};

export const trackEvent = (eventName, properties = {}) => {
    mixpanel.track(eventName, properties);
};

export const registerOrder = (order) => {
    const properties = {
        'Order Id': order.id,
        Restaurant: order.affiliation,
        'Order Date': order.date,
        Items: order.items.length,
        Subtotal: order.subtotal,
        'Delivery Fee': order.deliveryFee,
        Total: order.total
    };
    mixpanel.track('Order Placed', properties);
    mixpanel.people.increment('Orders placed', 1);
    mixpanel.people.increment('Revenue', order.total);
    mixpanel.people.track_charge(order.total);
};
