import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'js/initializers/createStore';
import LoadingLayout from 'js/components/layout/LoadingLayout';
import ScrollToTop from 'js/components/common/ScrollToTop';
import ApplyBranding from 'js/components/layout/ApplyBranding';
import Routes from './Routes';

import 'styles/index.scss';

const App = ({ actions: { loadAppSettings } }) => {
    const isLoading = useSelector((state) => state.app.loading);

    useEffect(() => {
        loadAppSettings();
    }, [loadAppSettings]);

    if (isLoading) return <LoadingLayout />;
    return (
        <ConnectedRouter history={history}>
            <ScrollToTop />
            <ApplyBranding />
            <Routes />
        </ConnectedRouter>
    );
};

App.propTypes = {
    actions: PropTypes.object
};

export default App;
