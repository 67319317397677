import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Card from './Card';
import * as Utilities from 'js/utils/utilities';
import Constants from 'js/utils/constants';

const Home = ({ actions: { showAppModal, setHeader } }) => {
    const { selectedMenu, selectedRestaurant } = useSelector((state) => state.companyData);
    const categories = Utilities.filteredAndSortedCategories(selectedMenu);
    const cards = categories.map((category) => (
        <Card key={category.id} category={category} selectedRestaurant={selectedRestaurant} />
    ));

    useEffect(() => {
        setHeader({
            title: null,
            background_img: null
        });
        if (Utilities.isSinpeRedirect()) {
            const sinpeData = Utilities.extractSinpeParameters();
            showAppModal(true, Constants.SINPE_REDIRECT_MODAL, sinpeData, {
                modalFull: false,
                closeButton: false
            });
        }
    }, [showAppModal, setHeader]);

    return (
        <div id="container" className="row no-gutters">
            {cards}
        </div>
    );
};

Home.propTypes = {
    actions: PropTypes.object
};

export default Home;
