import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { handleOpenShoppingCart } from 'js/stores/app/actions';

const Content = ({ children, contentClass }) => {
    const dispatch = useDispatch();
    const { shoppingCartOpen } = useSelector((state) => state.app);

    const handleCloseMenu = (e) => {
        e.stopPropagation();
        const classList = document.body.classList;
        if (classList.contains('pushed-right')) {
            dispatch(handleOpenShoppingCart(!shoppingCartOpen));
        }
    };

    return (
        <React.Fragment>
            <div id="white-background"></div>
            <div id="background-color"></div>
            <div id="content" className={contentClass} onClick={handleCloseMenu}>
                {children}
            </div>
        </React.Fragment>
    );
};

Content.propTypes = {
    children: PropTypes.array.isRequired,
    contentClass: PropTypes.string
};

export default Content;
