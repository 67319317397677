import { createAction } from '@reduxjs/toolkit';
import Constants from '../../utils/constants';
import * as companyDataActions from '../companyData/actions';
import * as itemActions from '../items/actions';
import * as orderActions from '../order/actions';
import * as sinpeActions from '../sinpe/actions';

const objectModalMapper = (key) => {
    switch (key) {
        case Constants.SINPE_REDIRECT_MODAL:
            return sinpeActions.showSinpeRedirectModal;
        case Constants.PLACE_ORDER_MODAL:
            return orderActions.loadPlaceOrderModal;
        case Constants.ADD_TO_CART_MODAL:
            return itemActions.singleItem;
        case Constants.SELECT_RESTAURANT_MODAL:
            return companyDataActions.showSelectRestaurantModal;
        default:
            return null;
    }
};

export const showModal = createAction(
    'SHOW_MODAL',
    (open, componentType, componentProps, modalProps) => ({
        payload: {
            open,
            componentType,
            componentProps,
            modalProps
        }
    })
);

export const showAppModal = (open, componentType, componentProps = {}, modalProps = {}) => {
    return (dispatch) => {
        if (objectModalMapper(componentType)) {
            dispatch(objectModalMapper(componentType)(componentProps));
        }
        dispatch(showModal(open, componentType, componentProps, modalProps));
    };
};
