import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const locales = { es };

export default function (date, formatStr = 'PP') {
    return format(date, formatStr, {
        locale: locales[window.__localeId__] // or global.__localeId__
    });
}
