import React from 'react';
import PropTypes from 'prop-types';
import ShoppingCart from '../shoppingCart';
import ContentContainer from './Content';
import Header from './header';
import Footer from './footer';
import AppModal from '../appModal';
import ShoppingCartButton from './ShoppingCartButton';
import { useRouteMatch } from 'react-router-dom';
import { ROUTES } from 'js/utils/constants';
import FilterPlanComponent from 'js/components/plan/FilterPlanComponent';

const Appetit = ({ children }) => {
    // hide footer when route matches item view
    const match = useRouteMatch(ROUTES.ITEM);

    return (
        <>
            <ShoppingCart />
            <ContentContainer contentClass="main-content-imgs">
                <Header rightButton={<FilterPlanComponent component={ShoppingCartButton} />} />
                <div id="shadow"></div>
                <div className="wrapper">{children}</div>
                {!match && <Footer />}
            </ContentContainer>
            <AppModal />
        </>
    );
};

Appetit.propTypes = {
    children: PropTypes.node.isRequired
};

export default Appetit;
