import React from 'react';
import PropTypes from 'prop-types';
import Item from './Item';

import '../../../../styles/menu/menu_items.scss';

const ItemsList = ({ items }) => {
    const itemsList = items && items.map((item) => <Item key={item.id} item={item} />);

    return (
        <div className="full-width">
            <section className="section item-digitalmenu">
                <div className="container">
                    <div className="row no-gutters products-list">{itemsList}</div>
                </div>
            </section>
        </div>
    );
};

ItemsList.propTypes = {
    items: PropTypes.array
};

export default ItemsList;
