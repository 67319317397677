import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router';
import * as Utils from 'js/utils/utilities';
import Constants from 'js/utils/constants';
import RedirectMobile from './RedirectMobile';
import RedirectDesktop from './RedirectDesktop';

import 'styles/base/loading.scss';

const RedirectModal = ({ sinpeData, actions: { showAppModal } }) => {
    const history = useHistory();
    const sinpeHref = Utils.buildSinpeSms(
        sinpeData.order,
        sinpeData.amount,
        sinpeData.bank,
        sinpeData.phone
    );

    const handleCloseModal = (timer, cb = () => {}) => {
        timer = setTimeout(() => {
            cb();
            handlePageVisibility();
        }, 5000);
    };

    const handlePageVisibility = () => {
        let hidden, visibilityChange;
        if (typeof document.hidden !== 'undefined') {
            hidden = 'hidden';
            visibilityChange = 'visibilitychange';
        } else if (typeof document.msHidden !== 'undefined') {
            hidden = 'msHidden';
            visibilityChange = 'msvisibilitychange';
        } else if (typeof document.webkitHidden !== 'undefined') {
            hidden = 'webkitHidden';
            visibilityChange = 'webkitvisibilitychange';
        }

        // change view when user comes back from SMS app
        const handleVisibilityChange = () => {
            if (!document[hidden]) {
                showAppModal(false, Constants.SINPE_REDIRECT_MODAL, {});
                history.push('/');
            }
        };
        if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
            showAppModal(false, Constants.SINPE_REDIRECT_MODAL, {});
        } else {
            document.addEventListener(visibilityChange, handleVisibilityChange, false);
        }
    };

    return (
        <Card>
            <Card.Body className="mt-5">
                {sinpeData.isMobile ? (
                    <RedirectMobile handleCloseModal={handleCloseModal} sinpeHref={sinpeHref} />
                ) : (
                    <RedirectDesktop handleCloseModal={handleCloseModal} />
                )}
            </Card.Body>
        </Card>
    );
};

RedirectModal.propTypes = {
    sinpeData: PropTypes.object,
    actions: PropTypes.object
};

export default RedirectModal;
