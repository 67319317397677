import React from 'react';
import { useSelector } from 'react-redux';
import ScrollableMenu from 'js/components/common/ScrollableMenu';
import * as Utils from 'js/utils/utilities';
import NoMenu from './NoMenu';
import ItemsList from './ItemsList';

import 'styles/menu/menu_items.scss';

const Menu = () => {
    const { selectedMenu: menu } = useSelector((state) => state.companyData);
    const categories = Utils.filteredAndSortedCategories(menu);
    const menuExists = categories.length;

    const categoriesList =
        menuExists &&
        categories.map((category) => (
            <ScrollableMenu.Section key={category.id} id={category.slug} title={category.name}>
                <ItemsList items={category.items} />
            </ScrollableMenu.Section>
        ));

    const navItems =
        menuExists &&
        categories.map((category) => {
            const key = `${category.slug}_${category.id}`;
            const hash = Utils.categoryToHash(category);
            const navProps = { id: key, hash, title: category.name };
            return <ScrollableMenu.NavigatorItem key={key} {...navProps} />;
        });

    return menuExists ? (
        <ScrollableMenu>
            <ScrollableMenu.Navigator defaultActiveKey={Utils.categoryToHash(categories[0])}>
                {navItems}
            </ScrollableMenu.Navigator>
            <ScrollableMenu.Container>{categoriesList}</ScrollableMenu.Container>
        </ScrollableMenu>
    ) : (
        <NoMenu />
    );
};

Menu.propTypes = {};

export default Menu;
