import moment from 'moment';
import _ from 'lodash';
import * as GA from './googleAnalytics';
// import * as Pixel from './pixel';
import * as Mixpanel from './mixpanel';
import Constants from './constants';
import { getEnvironment } from './utilities';

const shouldNotTrack = getEnvironment() !== Constants.PRODUCTION;

export const initRestaurantTrackers = (restaurants) => {
    if (shouldNotTrack) return;
    const trackers = _.chain(restaurants)
        .filter((r) => !!r.ga_code)
        .map((r) => ({ name: _.snakeCase(r.name), trackingId: r.ga_code }))
        .value();

    if (!trackers.length) return;

    GA.initializeGA(trackers);
    // Pixel.initialize(codes.pixel);
};

export const initializeMixpanel = (code) => {
    if (shouldNotTrack || !code) return;
    Mixpanel.initialize(code);
};

export const trackPage = (page, options = {}) => {
    if (shouldNotTrack) return;
    GA.trackPage(page, options);
};

export const addImpressions = (items = []) => {
    if (shouldNotTrack) return;
    GA.addImpressions(items);
};

export const registerItemClick = (item, index) => {
    if (shouldNotTrack) return;
    GA.registerItemClick(item, index);
};

export const registerViewItem = (item) => {
    if (shouldNotTrack) return;
    GA.registerViewItem(item);
    Mixpanel.trackEvent('Item Viewed', { ...item });
    // Pixel.trackCustomEvent('Item Viewed', { ...item });
};

export const registerCartItem = (item, action) => {
    if (shouldNotTrack) return;
    GA.registerCartItem(item, action);
    Mixpanel.registerCartItem(item, action);
    // Pixel.registerCartItem(item, action);
};

export const registerCheckoutStep = (items = [], step) => {
    if (shouldNotTrack) return;
    GA.registerCheckoutStep(items, step);

    if (step === 1) {
        Mixpanel.trackEvent('Cart Viewed', {
            'Items in cart': items.length
        });
    } else if (step === 2) {
        Mixpanel.trackEvent('Checkout Modal Viewed');
    }
};

export const registerCheckoutOption = (name, option, step) => {
    if (shouldNotTrack) return;
    GA.registerCheckoutOption(option, step);
    if (name === Constants.USER.DELIVERY) {
        Mixpanel.trackEvent('Delivery Method Changed', {
            'Delivery method': option
        });
        // Pixel.trackCustomEvent('AddDeliveryInfo', {
        //     'Delivery method': option
        // });
    } else if (name === Constants.USER.PAYMENT_METHOD) {
        Mixpanel.trackEvent('Payment Method Changed', {
            'Payment method': option
        });
        // Pixel.trackEvent('AddPaymentInfo', {
        //     'Payment method': option
        // });
    }
};

export const registerPlaceOrder = (order, callback) => {
    if (shouldNotTrack) {
        if (callback) {
            callback();
        }
        return;
    }
    Mixpanel.registerOrder(order);
    // Pixel.registerOrder(order);
    GA.registerPlaceOrder(order, callback);
};

export const setPeople = (data) => {
    if (shouldNotTrack) return;
    Mixpanel.setPeople(data);
};

export const setPeopleOnce = (data) => {
    if (shouldNotTrack) return;
    Mixpanel.setPeopleOnce(data);
};

export const addToRestaurantGroup = (restaurant) => {
    if (shouldNotTrack) return;
    Mixpanel.setGroup('restaurant', restaurant);
};

export const trackEvent = (eventName, properties) => {
    if (shouldNotTrack) return;
    Mixpanel.trackEvent(eventName, properties);
};

export const registerSaveAddress = (form) => {
    if (shouldNotTrack) return;
    Mixpanel.trackEvent('Address Saved', {
        Coordinates: form[Constants.USER.ADDRESS_COORDS],
        Location: form[Constants.USER.LOCATION],
        References: form[Constants.USER.ADDRESS_REFERENCES],
        Distance: form[Constants.USER.ADDRESS_DISTANCE],
        Duration: form[Constants.USER.ADDRESS_DURATION]
    });
};

export const registerUser = (order, restaurant) => {
    if (shouldNotTrack) return;
    setPeople(order);
    setPeopleOnce({
        $created: moment().toISOString(),
        'Restaurant Registrant': restaurant
    });
    addToRestaurantGroup(restaurant);
};
