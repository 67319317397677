/* global google */
import isMobile from './isMobile';
import _ from 'lodash';

export const geocodeLocation = (geocoder, mapCenter, fallbackCoords, cb) => {
    geocoder.geocode({ location: mapCenter }, (results, status) => {
        if (status !== 'OK' || !results[0]) {
            return;
        }
        getDistanceFromRestaurant(fallbackCoords, mapCenter, (distance, duration) => {
            console.log(distance);
            cb(results[0], mapCenter, distance, duration);
        });
    });
};

export const initMap = (center) => {
    const mapOptions = {
        zoom: 16,
        disableDefaultUI: true,
        zoomControl: true,
        center
    };

    return new google.maps.Map(document.getElementById('map'), mapOptions);
};

export const addDefaultMarker = (map) => {
    const marker = new google.maps.Marker({
        map
    });
    marker.bindTo('position', map, 'center');
};

export const getDistanceFromRestaurant = (restaurantLocation, userLocation, cb = () => {}) => {
    const service = new google.maps.DistanceMatrixService();
    const restaurantPoint = new google.maps.LatLng(restaurantLocation.lat, restaurantLocation.lng);
    const userPoint = new google.maps.LatLng(userLocation.lat, userLocation.lng);
    service.getDistanceMatrix(
        {
            origins: [restaurantPoint],
            destinations: [userPoint],
            travelMode: 'DRIVING',
            unitSystem: google.maps.UnitSystem.METRIC
        },
        (response, status) => {
            if (status !== 'OK') {
                console.log('Error: ' + status);
                cb(NaN, NaN);
            } else {
                let row = response.rows && response.rows.length && response.rows[0];
                let element = row.elements && row.elements.length && row.elements[0];
                if (element.status === 'OK') {
                    cb(element.distance.value, element.duration.value);
                    return;
                }
            }
            cb(NaN, NaN);
            return;
        }
    );
};

export const addUserLocationButton = (map, fallbackCoords, cb = () => {}) => {
    if (!isMobile()) return;
    let controlDiv = document.createElement('div');

    let firstChild = document.createElement('button');
    firstChild.style.backgroundColor = '#fff';
    firstChild.style.border = 'none';
    firstChild.style.outline = 'none';
    firstChild.style.width = '40px';
    firstChild.style.height = '40px';
    firstChild.style.borderRadius = '2px';
    firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
    firstChild.style.cursor = 'pointer';
    firstChild.style.marginRight = '10px';
    firstChild.style.padding = '0px';
    firstChild.title = 'Your Location';
    controlDiv.appendChild(firstChild);

    let secondChild = document.createElement('div');
    secondChild.style.margin = 'auto';
    secondChild.style.width = '18px';
    secondChild.style.height = '18px';
    secondChild.style.backgroundImage =
        'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
    secondChild.style.backgroundSize = '180px 18px';
    secondChild.style.backgroundPosition = '0px 0px';
    secondChild.style.backgroundRepeat = 'no-repeat';
    secondChild.id = 'user-location-img';
    firstChild.appendChild(secondChild);

    firstChild.addEventListener('click', () => {
        fetchUserCoordinates(map, fallbackCoords, cb);
    });

    controlDiv.index = 1;
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
};

export const fetchUserCoordinates = (map, fallbackCoords, cb) => {
    let imgX = '0';
    let interval = setInterval(() => {
        if (imgX === '-18') imgX = '0';
        else imgX = '-18';
        addCssToElement('user-location-img', { 'background-position': `${imgX}px 0px` });
    }, 500);

    if (isMobile() && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                let pos = new google.maps.LatLng(
                    position.coords.latitude,
                    position.coords.longitude
                );

                handleLocation(map, pos, interval, '-144px 0px', cb);
            },
            () => {
                handleLocation(map, fallbackCoords, interval, '0px 0px', cb);
            }
        );
    } else {
        handleLocation(map, fallbackCoords, interval, '0px 0px', cb);
    }
};

export const handleLocation = (map, pos, interval, css, cb) => {
    map.setCenter(pos);
    clearInterval(interval);
    addCssToElement('user-location-img', { 'background-position': css });
    cb(map.getCenter().toJSON());
};

export const deactivateLocationButton = () => {
    addCssToElement('user-location-img', { 'background-position': '0px 0px' });
};

export const addCssToElement = (elementId, styles) => {
    let element = document.getElementById(elementId),
        elementStyle = element ? element.style : {};
    if (!element) return;
    Object.keys(styles).forEach((s) => {
        elementStyle[s] = styles[s];
    });
};

export const convertToLatLng = (center) => {
    if (_.isFunction(center.lat)) {
        return center;
    } else {
        return new google.maps.LatLng({ lat: parseFloat(center.lat), lng: parseFloat(center.lng) });
    }
};

export const validatePath = (paths) => _.map(paths, (path) => convertToLatLng(path));

export const createPolygon = (path) =>
    new google.maps.Polygon({
        paths: validatePath(path)
    });

export const createCircle = (circle) =>
    new google.maps.Circle({ center: convertToLatLng(circle.center), radius: +circle.radius });

export const polygonContainsLocation = (polygon, location) => {
    return google.maps.geometry.poly.containsLocation(convertToLatLng(location), polygon);
};

export const distanceBetweenCircleAndLocation = (circle, location) =>
    google.maps.geometry.spherical.computeDistanceBetween(
        convertToLatLng(location),
        circle.getCenter()
    );

export const circleContainsLocation = (circle, location) =>
    distanceBetweenCircleAndLocation(circle, location) <= circle.getRadius();

export const zoneContainsLocation = (zone, userLocation) => {
    if (!['circle', 'polygon'].includes(zone.type)) return false;
    if (zone.type === 'circle' && (!zone.shape || !zone.shape.radius)) return false;
    if (zone.type === 'polygon' && (!zone.shape || !zone.shape.path)) return false;
    return zone.type === 'circle'
        ? circleContainsLocation(createCircle(zone.shape), userLocation)
        : polygonContainsLocation(createPolygon(zone.shape.path), userLocation);
};

export const distanceFromUserToCircleEdge = (circle, location) => {
    const distanceToCenter = distanceBetweenCircleAndLocation(circle, location);
    return circle.getRadius() - distanceToCenter;
};
