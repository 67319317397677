import React from 'react';
import { Button } from 'react-bootstrap';

const categoryBack = `/images/icons/back.svg`;
const OrderButton = ({ nextStep, title }) => (
    <Button className="btn-lg btn-block" onClick={nextStep}>
        {title} <img src={categoryBack} alt={title} title={title} />
    </Button>
);

OrderButton.propTypes = {};

export default OrderButton;
