import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form } from 'react-bootstrap';
import Constants from 'js/utils/constants';
import { toCurrency, isModifierItemChecked } from 'js/utils/utilities';

const ModifierGroupRow = ({ modifierItem, modifierGroup, handleItemSelect }) => {
    const isChecked = isModifierItemChecked(modifierItem);
    const isModifierItemSoldOut = modifierItem.status === Constants.ITEM_STATUS.sold_out;
    const disabledClass =
        (modifierGroup.isValid && !isChecked) || isModifierItemSoldOut ? 'disabled' : '';
    const checkedClass = !!isChecked ? 'checked' : '';
    const label = isModifierItemSoldOut ? `${modifierItem.name} (Agotado)` : modifierItem.name;
    return (
        <Form.Row className={`${disabledClass} ${checkedClass}`}>
            <Col xs={8}>
                {modifierGroup.quantity === 1 && modifierGroup.required ? (
                    <Form.Check
                        type="radio"
                        id={`${modifierItem.id}_${modifierGroup.id}`}
                        label={label}
                        name={modifierGroup.id}
                        value={modifierItem.id}
                        checked={!!isChecked}
                        disabled={isModifierItemSoldOut}
                        onChange={(e) => handleItemSelect(modifierItem, e.target.checked)}
                    />
                ) : (
                    <Form.Check
                        type="checkbox"
                        id={`${modifierItem.id}_${modifierGroup.id}`}
                        label={label}
                        value={modifierItem.id}
                        checked={!!isChecked}
                        disabled={(modifierGroup.isValid && !isChecked) || isModifierItemSoldOut}
                        onChange={(e) => handleItemSelect(modifierItem, e.target.checked)}
                    />
                )}
            </Col>
            <Col xs={4} className="text-right">
                {modifierItem.price > 0 && <span>+ {toCurrency(modifierItem.price)}</span>}
            </Col>
        </Form.Row>
    );
};

ModifierGroupRow.propTypes = {
    modifierItem: PropTypes.object.isRequired,
    modifierGroup: PropTypes.object.isRequired,
    handleItemSelect: PropTypes.func.isRequired
};

export default ModifierGroupRow;
