import React from 'react';

const cartEmpty = `/images/icons/cart-empty.svg`;

const EmptyCart = () => (
    <div id="empty-cart">
        <img src={cartEmpty} alt="" />
        <h4>Su carrito está vacío</h4>
        <p>Añade algo que está haciendo hambre :D</p>
    </div>
);

export default EmptyCart;
