import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Logo = ({ companyLogo, className }) => (
    <Link className={className} to="/">
        <img className="logo" src={companyLogo} alt="Logo" title="Logo" />
    </Link>
);

Logo.propTypes = {
    companyLogo: PropTypes.string,
    className: PropTypes.string
};

export default Logo;
