import { createReducer } from '@reduxjs/toolkit';
import initialState from '../../initializers/initialState';
import * as headerActions from './actions';

const header = createReducer(initialState.header, {
    [headerActions.setHeader]: (state, action) => ({
        ...state,
        ...action.payload
    })
});

export default header;
