import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import ModifierGroupRow from './ModifierGroupRow';

const ModifierGroup = ({ modifier, updateItemModifiers }) => {
    let instructionsText = `Opcional. Seleccione hasta ${modifier.quantity}.`;
    if (modifier.required) {
        instructionsText = 'Requerido';
        if (modifier.quantity > 1) {
            instructionsText += `. Seleccione ${modifier.quantity}.`;
        }
    }

    const handleItemSelect = (modifierItem, checked) => {
        updateItemModifiers({ modifier, modifierItem, checked });
    };

    return modifier ? (
        <Form.Group className="modifiers">
            <Form.Label className="font-weight-bold mb-0">{modifier.title}</Form.Label>
            <Form.Text className="mb-3 mt-0">{instructionsText}</Form.Text>
            {modifier.items &&
                _.sortBy(modifier.items, [(o) => +o.sort_order]).map((modifierItem, index) => (
                    <ModifierGroupRow
                        key={`${modifierItem.sort_order}_${index}`}
                        modifierItem={modifierItem}
                        modifierGroup={modifier}
                        handleItemSelect={handleItemSelect}
                    />
                ))}
        </Form.Group>
    ) : null;
};

ModifierGroup.propTypes = {
    modifier: PropTypes.object.isRequired,
    updateItemModifiers: PropTypes.func
};

export default ModifierGroup;
