import { createAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import * as Tracker from 'js/utils/tracker';
import Constants from 'js/utils/constants';
import {
    calculateModifierSubtotal,
    isModifierValid,
    isItemValid,
    calculateItemSubtotal
} from 'js/utils/cartUtils';
import { isModifierItemChecked } from 'js/utils/utilities';

export const updateItemOrderNotes = createAction('UPDATE_ITEM_ORDER_NOTES');
export const updateItemModifiers = createAction('UPDATE_ITEM_MODIFIERS');

export const showSingleItem = createAction('SHOW_SINGLE_ITEM', ({ item, action, showModal }) => {
    if (action === Constants.ADD && item) Tracker.registerViewItem(item);
    return {
        payload: {
            item,
            action,
            showModal
        }
    };
});

export const updateAddToCartItem = createAction('UPDATE_ADD_TO_CART_ITEM', (item, action) => {
    let newQuantity = item.quantity;
    const { quantity } = item;

    if (action === 'add') {
        newQuantity = quantity + 1;
    } else if (action === 'substract' && quantity > 1) {
        newQuantity = quantity - 1;
    }

    return {
        payload: {
            quantity: newQuantity
        }
    };
});

export const singleItem = ({ item: showingItem = {}, category, action, showModal }) => {
    return (dispatch, getState) => {
        if (_.isEmpty(showingItem) && !category && !action) {
            return;
        }
        // if editing item don't do any modifications
        if (action === Constants.EDIT) {
            return dispatch(showSingleItem({ item: showingItem, action, showModal }));
        }

        const {
            companyData: {
                selectedRestaurant: { modifiers, items }
            }
        } = getState();
        const itemModifierIds = _.map(showingItem.modifiers || [], 'modifier_id');
        const itemModifiers = itemModifierIds.length
            ? modifiers
                  // filter modifiers to get only modifiers assigned to item
                  .filter((m) => itemModifierIds.includes(m.id))
                  .map((modifier) => {
                      // find the current evaluated modifier in its minified version in showing item
                      // e.g { modifier_id: 1, sort_order: 0 }
                      const showingItemModifier = _.find(showingItem.modifiers, [
                          'modifier_id',
                          modifier.id
                      ]);
                      // pluck item_id from modifier items
                      // e.g: [1, 2, 3]
                      const modifierItemsIds = _.map(modifier.items || [], 'item_id');
                      // get items list merged with minified versions
                      const modifierItems = items
                          .filter((i) => modifierItemsIds.includes(i.id))
                          .map((item) => {
                              const modifierItem = {
                                  ...item,
                                  ...(_.find(modifier.items, ['item_id', item.id]) || {})
                              };
                              return {
                                  ...modifierItem,
                                  checked: isModifierItemChecked(modifierItem)
                              };
                          });
                      const unifiedModifier = { ...modifier, ...showingItemModifier };
                      return {
                          ...unifiedModifier,
                          isValid: isModifierValid(unifiedModifier),
                          subtotal: calculateModifierSubtotal(unifiedModifier),
                          items: modifierItems
                      };
                  })
            : [];
        const unifiedItem = {
            ...showingItem,
            modifiers: itemModifiers,
            quantity: 1,
            orderNotes: '',
            category: category.name
        };
        const item = {
            ...unifiedItem,
            subtotal: calculateItemSubtotal(unifiedItem),
            isValid: isItemValid(unifiedItem)
        };
        dispatch(showSingleItem({ item, action, showModal }));
    };
};
