/* global google */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAccordionToggle } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Map from '../../common/Map';
import DynamicForm from '../../common/DynamicForm';
import OrderCheckoutButton from 'js/components/placeOrderModal/components/OrderCheckoutButton';
import * as FormUtils from 'js/utils/formUtils';
import * as Utilities from 'js/utils/utilities';
import * as MapUtils from 'js/utils/mapUtils';
import Constants from 'js/utils/constants';

const categoryBack = `/images/icons/back-black.svg`;
const AddressForm = ({
    actions: { setActiveKey, handleSaveAddress, handleValidateAddressForm }
}) => {
    const {
        shoppingCart,
        companyData,
        addressForm: { addressFormErrors }
    } = useSelector((state) => state);
    // handle address form state in the component before saving in redux
    const [form, setForm] = useState({
        [Constants.USER.LOCATION]: shoppingCart[Constants.USER.LOCATION],
        [Constants.USER.ADDRESS_COORDS]: Utilities.getDefaultCoordinates(shoppingCart, companyData),
        [Constants.USER.ADDRESS_REFERENCES]: shoppingCart[Constants.USER.ADDRESS_REFERENCES],
        [Constants.USER.ADDRESS_DISTANCE]: shoppingCart[Constants.USER.ADDRESS_DISTANCE],
        [Constants.USER.ADDRESS_DURATION]: shoppingCart[Constants.USER.ADDRESS_DURATION]
    });
    const toggleOrderDetails = useAccordionToggle('0', () => setActiveKey('0'));
    const goBack = (e) => {
        setForm({
            [Constants.USER.LOCATION]: shoppingCart[Constants.USER.LOCATION],
            [Constants.USER.ADDRESS_COORDS]: Utilities.getDefaultCoordinates(
                shoppingCart,
                companyData
            ),
            [Constants.USER.ADDRESS_REFERENCES]: shoppingCart[Constants.USER.ADDRESS_REFERENCES],
            [Constants.USER.ADDRESS_DISTANCE]: shoppingCart[Constants.USER.ADDRESS_DISTANCE],
            [Constants.USER.ADDRESS_DURATION]: shoppingCart[Constants.USER.ADDRESS_DURATION]
        });
        toggleOrderDetails(e);
    };
    const handleCoords = (center) => {
        setForm({
            ...form,
            [Constants.USER.ADDRESS_COORDS]: center,
            [Constants.USER.ADDRESS_REFERENCES]: ''
        });
    };

    const trackForm = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };
    const saveAddress = () => {
        if (!FormUtils.addressFormIsValid(form, companyData)) {
            return handleValidateAddressForm(form);
        }

        const geocoder = new google.maps.Geocoder();
        MapUtils.geocodeLocation(
            geocoder,
            form[Constants.USER.ADDRESS_COORDS],
            companyData.selectedRestaurant.address.point,
            (geocodeInfo, mapCoordinates, distance, duration) => {
                if (!geocodeInfo || !mapCoordinates || !geocodeInfo.address_components) {
                    return;
                }
                const streetElement = geocodeInfo.address_components[0].long_name;
                const neighborhoodElement = geocodeInfo.address_components[1].long_name;
                const addressForm = {
                    ...form,
                    [Constants.USER.LOCATION]: `${streetElement}, ${neighborhoodElement}`,
                    [Constants.USER.ADDRESS_COORDS]: mapCoordinates,
                    [Constants.USER.ADDRESS_DISTANCE]: distance,
                    [Constants.USER.ADDRESS_DURATION]: duration,
                    [Constants.USER.ADDRESS]: form[Constants.USER.ADDRESS_REFERENCES]
                };

                handleSaveAddress(addressForm, () => {
                    toggleOrderDetails();
                });
            }
        );
    };
    return (
        <React.Fragment>
            <button className="btn-back" onClick={goBack}>
                <img src={categoryBack} alt="atrás" title="atrás" />
            </button>
            <h5>Dirección de Envío</h5>
            <div className="container">
                <p className="intro-label">
                    Arrastrá el mapa para posicionarlo en la ubicación deseada o presioná el botón
                    de posicionamiento para utilizar tu ubicación actual.
                </p>
                <div className="gmap">
                    <Map
                        handleCoords={handleCoords}
                        center={form[Constants.USER.ADDRESS_COORDS]}
                        fallbackCoords={Utilities.sanitizeCoords(
                            companyData.selectedRestaurant.address.point
                        )}
                    />
                </div>
                {addressFormErrors[Constants.USER.ADDRESS_COORDS] && (
                    <small className="text-muted error form-text">
                        {addressFormErrors[Constants.USER.ADDRESS_COORDS]}
                    </small>
                )}
                <div className="address-inputs">
                    <DynamicForm
                        data={form}
                        formKeys={FormUtils.getAddressFormKeys()}
                        handler={trackForm}
                        usePlaceholder={true}
                        errors={addressFormErrors}
                    />
                </div>
                <div className="save-address">
                    <OrderCheckoutButton nextStep={saveAddress} title={'Guardar Dirección'} />
                </div>
            </div>
        </React.Fragment>
    );
};

AddressForm.propTypes = {
    actions: PropTypes.object
};

export default AddressForm;
