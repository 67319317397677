import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card } from 'react-bootstrap';
import OrderDetails from './OrderDetails';
import OrderPayment from '../../paymentMethods';
import AddressForm from '../../addressForm';
import WhatsappRedirect from './WhatsappRedirect';
import ProcessingOrder from './ProcessingOrder';

// PaymentTypes no tiene un default export entonces se importa
// para que corra la función inmediatamente
import 'js/components/paymentMethods/components/PaymentTypes';

const OrderModal = (props) => {
    return (
        <Accordion defaultActiveKey={props.activeKey}>
            <Card>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <OrderDetails {...props} />
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <AddressForm {...props} />
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>
                        <OrderPayment {...props} />
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>
                        <WhatsappRedirect />
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>
                        <ProcessingOrder {...props} />
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

OrderModal.propTypes = {
    activeKey: PropTypes.string
};

export default OrderModal;
