import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import DigitalMenuLayout from '../layout/DigitalMenuLayout';
import DigitalMenu from '../digitalMenu';
import CurrentPlan from 'js/components/plan/CurrentPlan';

const RestaurantRoutes = () => {
    let { path } = useRouteMatch();

    if (!CurrentPlan.isFree()) {
        return <Redirect to="/" />;
    }
    return (
        <DigitalMenuLayout>
            <Switch>
                <Route path={path}>
                    <DigitalMenu />
                </Route>
            </Switch>
        </DigitalMenuLayout>
    );
};

export default RestaurantRoutes;
