import React from 'react';
import PropTypes from 'prop-types';
import ShoppingCartList from './ShoppingCartItemsList';
import ContinueButton from './ContinueButton';
import OrderDetails from './OrderDetails';
import NextContainer from './NextContainer';
import EmptyCart from './EmptyCart';

const ShoppingCart = (props) => {
    const {
        subtotal,
        items,
        actions: { continueToDetailsAction }
    } = props;
    return (
        <header id="shopping-cart">
            {items.length > 0 ? (
                <>
                    <ShoppingCartList {...props} />
                    <NextContainer>
                        <OrderDetails subtotal={subtotal} />
                        <ContinueButton
                            continueToDetailsAction={continueToDetailsAction}
                            items={items}
                        />
                    </NextContainer>
                </>
            ) : (
                <EmptyCart />
            )}
        </header>
    );
};

ShoppingCart.propTypes = {
    subtotal: PropTypes.number,
    items: PropTypes.array,
    actions: PropTypes.object
};

export default ShoppingCart;
