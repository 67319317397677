import React from 'react';
import PropTypes from 'prop-types';
import { toCurrency } from '../../../utils/utilities';

const OrderDetails = ({ subtotal }) => {
    return (
        <div className="container">
            <div className="row">
                <h5 className="main-total-price">
                    Subtotal: <span>{toCurrency(subtotal)}</span>
                </h5>
            </div>
        </div>
    );
};

OrderDetails.propTypes = {
    subtotal: PropTypes.number.isRequired
};

export default OrderDetails;
