import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import 'styles/base/loading.scss';

const Redirect = ({ handleCloseModal }) => {
    useEffect(() => {
        let timer;
        handleCloseModal(timer);
        return () => {
            clearTimeout(timer);
        };
    });
    return (
        <div>
            <React.Fragment>
                <h5 className="center">Estás a un paso de completar tu pedido...</h5>
                <figure className="img-sinpe">
                    <img src="/images/sinpe-desktop.png" alt="Sinpe" title="Sinpe" />
                </figure>
                <p className="note-wa">
                    Ve a WhatsApp en tu CELULAR y abrí el link que se encuentra al final de la
                    orden.
                </p>
            </React.Fragment>
        </div>
    );
};

Redirect.propTypes = {
    handleCloseModal: PropTypes.func
};

export default Redirect;
