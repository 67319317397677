import { createReducer } from '@reduxjs/toolkit';
import initialState from '../../initializers/initialState';
import * as modalActions from './actions';

const modal = createReducer(initialState.modal, {
    [modalActions.showModal]: (state, action) => ({
        ...state,
        open: action.payload.open,
        modalProps: action.payload.modalProps,
        componentType: action.payload.componentType,
        componentProps: action.payload.componentProps
    })
});

export default modal;
