import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Constants from 'js/utils/constants';
import ItemDetails from '../../itemDetails';

const ItemModal = ({ actions: { handleAddItemToCart, handleEditShoppingCartItem } }) => {
    const action = useSelector((state) => state.itemDetails.action);
    const addToCartHandler =
        action === Constants.ADD ? handleAddItemToCart : handleEditShoppingCartItem;
    return (
        <div className="item-modal">
            <ItemDetails modalMode={true} handler={addToCartHandler} />
        </div>
    );
};

ItemModal.propTypes = {
    actions: PropTypes.object,
    action: PropTypes.string
};

export default ItemModal;
