import { createReducer } from '@reduxjs/toolkit';
import initialState from '../../initializers/initialState';
import * as shoppingCartActions from './actions';

const shoppingCart = createReducer(initialState.shoppingCart, {
    [shoppingCartActions.addItemToCart]: (state, action) => ({
        ...state,
        total: action.payload.total,
        subtotal: action.payload.subtotal,
        deliveryFee: action.payload.deliveryFee,
        otherCharges: action.payload.otherCharges,
        items: [...state.items, action.payload.item]
    }),
    [shoppingCartActions.removeCartItem]: (state, action) => ({
        ...state,
        total: action.payload.total,
        subtotal: action.payload.subtotal,
        deliveryFee: action.payload.deliveryFee,
        otherCharges: action.payload.otherCharges,
        items: [
            ...state.items.slice(0, action.payload.index),
            ...state.items.slice(action.payload.index + 1)
        ]
    }),
    [shoppingCartActions.updateCartItem]: (state, action) => ({
        ...state,
        total: action.payload.total,
        subtotal: action.payload.subtotal,
        deliveryFee: action.payload.deliveryFee,
        otherCharges: action.payload.otherCharges,
        items: [
            ...state.items.slice(0, action.payload.index),
            { ...state.items[action.payload.index], ...action.payload.item },
            ...state.items.slice(action.payload.index + 1)
        ]
    }),
    [shoppingCartActions.updateUserDefaults]: (state, action) => ({
        ...state,
        ...action.payload
    }),
    [shoppingCartActions.cleanShoppingCart]: (state) => ({
        ...state,
        subtotal: 0,
        total: 0,
        items: []
    }),
    [shoppingCartActions.setInitialCart]: (state, action) => ({
        ...state,
        ...action.payload
    }),
    [shoppingCartActions.setInitialUserDefaults]: (state, action) => ({
        ...state,
        ...action.payload
    })
});

export default shoppingCart;
