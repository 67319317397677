import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import AppetitLayout from 'js/components/layout/AppetitLayout';
import Terms from 'js/components/siteTerms/Terms';
import SingleItem from 'js/components/singleItem';
import Items from 'js/components/items';
import Home from 'js/components/home';
import { ROUTES } from 'js/utils/constants';
import CurrentPlan from 'js/components/plan/CurrentPlan';

const OrderingRoutes = () => {
    let { path } = useRouteMatch();
    if (CurrentPlan.isFree()) {
        return <Redirect to="/restaurante" />;
    }

    return (
        <AppetitLayout>
            <Switch>
                <Route exact path={ROUTES.TERMS}>
                    <Terms />
                </Route>
                <Route exact path={ROUTES.SINPE}>
                    <Home />
                </Route>
                <Route exact path={ROUTES.ITEMS_LIST}>
                    <Items />
                </Route>
                <Route exact path={ROUTES.ITEM}>
                    <SingleItem />
                </Route>
                <Route exact path={ROUTES.HOME}>
                    <Home />
                </Route>
                <Route exact path={path}>
                    <Redirect to={ROUTES.HOME} />
                </Route>
            </Switch>
        </AppetitLayout>
    );
};

export default OrderingRoutes;
