import React from 'react';
import PropTypes from 'prop-types';

const ItemQuantitySelector = ({ item, handleOnClick }) => {
    return (
        <div className="row quantity-content">
            <div className="col-6 quantity-item">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={(e) => handleOnClick(item, 'substract')}
                        >
                            <span className="controls">-</span>
                        </button>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        value={item.quantity}
                        disabled={true}
                        aria-describedby="button-addon1"
                        style={{ textAlign: 'center' }}
                    />
                    <div className="input-group-apoend">
                        <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={(e) => handleOnClick(item, 'add')}
                        >
                            <span className="controls">+</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

ItemQuantitySelector.propTypes = {
    item: PropTypes.object.isRequired,
    handleOnClick: PropTypes.func.isRequired
};

export default ItemQuantitySelector;
