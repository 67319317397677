import { createAction } from '@reduxjs/toolkit';
import * as Tracker from '../../utils/tracker';

export const openSideMenu = createAction('OPEN_SIDE_MENU');
export const openShoppingCart = createAction('OPEN_SHOPPING_CART');
export const appLoading = createAction('APP_LOADING');

export const handleOpenSideMenu = (open) => {
    return (dispatch) => {
        const classList = document.body;
        if (open) {
            classList.add('pushed-left');
        } else {
            classList.remove('pushed-left');
        }

        dispatch(openSideMenu(open));
    };
};

export const handleOpenShoppingCart = (open) => {
    return (dispatch, getState) => {
        const classList = document.body.classList,
            documentRoot = document.getElementsByTagName('html')[0].classList;
        if (open) {
            const {
                shoppingCart: { items }
            } = getState();
            Tracker.registerCheckoutStep(items, 1);
            classList.add('pushed-right');
            documentRoot.add('scroll-disabled');
        } else {
            classList.remove('pushed-right');
            documentRoot.remove('scroll-disabled');
        }

        dispatch(openShoppingCart(open));
    };
};
