import React from 'react';
import PropTypes from 'prop-types';
import { useAccordionToggle } from 'react-bootstrap';
import DynamicForm from 'js/components/common/DynamicForm';
import * as OrderUtils from 'js/utils/orderUtils';
import * as Utils from 'js/utils/utilities';
import * as FormUtils from 'js/utils/formUtils';
import Constants from 'js/utils/constants';
import StaticMap from '../../common/StaticMap';

const OrderContact = ({ shoppingCart, companyData, contactFormErrors, handleAddress, actions }) => {
    const { handleUserDefaultInput, setActiveKey } = actions;
    const togglePaymentMethod = useAccordionToggle('2', () => setActiveKey('2'));
    const orderIsExpress = OrderUtils.isExpress(shoppingCart);
    const mapCenter =
        shoppingCart[Constants.USER.ADDRESS_COORDS] || companyData.selectedRestaurant.address.point;
    const inputClicked = (e) => {
        if (e.target.name !== Constants.USER.PAYMENT_METHOD) {
            return;
        }
        togglePaymentMethod();
    };
    const handleMapClick = () => {
        if (!orderIsExpress) {
            return;
        }

        handleAddress();
    };
    const proceedToAddress = () => {
        handleAddress();
    };
    return (
        <div className="container">
            <div className="row">
                <div className="col-6 user-info img-map">
                    <StaticMap
                        isDisabled={!orderIsExpress}
                        center={Utils.sanitizeCoords(mapCenter)}
                        onClick={handleMapClick}
                    />
                    <DynamicForm
                        data={shoppingCart}
                        formKeys={FormUtils.getAddressContactFormKeys()}
                        clickHandler={proceedToAddress}
                        errors={contactFormErrors}
                        additionalData={companyData}
                        usePlaceholder={true}
                        useIcons={true}
                    />
                </div>
                <div className="col-6 user-info user-details">
                    <DynamicForm
                        data={shoppingCart}
                        formKeys={FormUtils.getOrderFormKeys()}
                        handler={handleUserDefaultInput}
                        clickHandler={inputClicked}
                        errors={contactFormErrors}
                        additionalData={companyData}
                        usePlaceholder={true}
                        useIcons={true}
                    />
                </div>
            </div>
        </div>
    );
};

OrderContact.propTypes = {
    shoppingCart: PropTypes.object,
    companyData: PropTypes.object,
    contactFormErrors: PropTypes.object,
    handleAddress: PropTypes.func,
    actions: PropTypes.object
};

export default OrderContact;
