import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import _ from 'lodash';
import ItemQuantitySelector from './ItemQuantitySelector';
import AddToCartButton from './AddToCartButton';
import ModifierGroup from './ModifierGroup';

const ItemForm = ({
    item,
    action,
    modalMode,
    handleAddToCart,
    actions: {
        updateAddToCartItem,
        updateItemOrderNotes,
        updateItemModifiers,
        handleRemoveCartItem
    }
}) => {
    return (
        <Form className="mt-4">
            {item.modifiers &&
                _.orderBy(item.modifiers, ['sort_order']).map((modifier) => (
                    <ModifierGroup
                        key={modifier.sort_order}
                        modifier={modifier}
                        updateItemModifiers={updateItemModifiers}
                    />
                ))}
            <div className="ins-section">
                <label>Instrucciones</label>
                <textarea
                    className="form-control"
                    placeholder="Notas adicionales..."
                    rows="3"
                    value={item.orderNotes}
                    onChange={(e) => updateItemOrderNotes(e.target.value)}
                ></textarea>
            </div>
            <ItemQuantitySelector item={item} handleOnClick={updateAddToCartItem} />
            <div className="btn-actions-modal">
                {action === 'edit' && (
                    <Button
                        variant="outline-danger btn-lg"
                        block
                        onClick={() => handleRemoveCartItem(item)}
                    >
                        Eliminar del Pedido
                    </Button>
                )}
                {modalMode && (
                    <AddToCartButton item={item} action={action} handler={handleAddToCart} />
                )}

                {!modalMode && (
                    <div className="btn-container">
                        <AddToCartButton item={item} action={action} handler={handleAddToCart} />
                    </div>
                )}
            </div>
        </Form>
    );
};

ItemForm.propTypes = {
    item: PropTypes.object,
    action: PropTypes.string,
    modalMode: PropTypes.bool,
    actions: PropTypes.object,
    handleAddToCart: PropTypes.func
};

export default ItemForm;
