import React from 'react';

const SocialList = ({ sn, url }) => (
    <li>
        <a href={url}>
            <i className={`fa fa-${sn}`}></i>
        </a>
    </li>
);

export default SocialList;
