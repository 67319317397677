import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SelectRestaurantModal from './components/SelectRestaurantModal';

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.modal.componentProps,
        ...ownProps
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectRestaurantModal);
