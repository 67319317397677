import React from 'react';
import PropTypes from 'prop-types';
import isMobile from 'js/utils/isMobile';

const HeaderNav = ({ bgImage, settings, children }) => {
    const branding = (settings.branding && settings.branding.header.backgroundImage) || null;
    const bg = (isMobile() && bgImage) || branding;
    const shadowClass = bgImage ? 'nav-shadow' : '';
    return (
        <div
            className={`nav nav-digital-menu ${shadowClass}`}
            style={bg ? { backgroundImage: `url(${bg})` } : {}}
        >
            <div className="container">{children}</div>
        </div>
    );
};

HeaderNav.propTypes = {
    bgImage: PropTypes.string,
    settings: PropTypes.object,
    children: PropTypes.array
};

export default HeaderNav;
