export const DEFAULT_BRANDING = {
    primary: '#007bff',
    secondary: '#6c757d',
    success: '#28a745',
    info: '#17a2b8',
    warning: '#ffc107',
    danger: '#dc3545',
    light: '#f8f9fa',
    dark: '#343a40'
};

export const ROUTES = {
    HOME: '/',
    TERMS: '/appetit-terms-of-service',
    ITEMS_LIST: '/:categorySlug',
    ITEM: '/:categorySlug/:itemId',
    SINPE: '/sinpe-payment'
};

export const NAV_APP = {
    GOOGLE: 'google',
    WAZE: 'waze'
};

export const RESTAURANT_STATUS = {
    online: 'En línea',
    offline: 'Desconectado',
    hidden: 'Oculto'
};

export const STATUS_TEXT = {
    offline: 'Restaurante desconectado sin recibir pedidos.',
    hidden: 'El restaurante en mantenimiento.',
    closed: 'El restaurante se encuentra cerrado',
    about: 'Cerramos en %t. ¡Realizá tu pedido ahora!'
};

export const STATUS_DESC = {
    ONLINE: 'online',
    OFFLINE: 'offline',
    HIDDEN: 'hidden'
};

export const WEEK_DAYS = {
    1: 'Lunes',
    2: 'Martes',
    3: 'Miércoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sábado',
    7: 'Domingo'
};

export default {
    PRODUCTION: 'production',
    QA: 'qa',
    DEV: 'development',
    ADD: 'add',
    EDIT: 'edit',
    SHOPPING_CART_LS: 'shopping_cart_ls',
    USER_DEFAULTS_LS: 'user_defaults_ls',
    STORED_ORDERS_LS: 'stored_orders_ls',
    USER_RESTAURANT_LS: 'user_restaurant_ls',
    EXPRESS_OPTION: 'express',
    PICKUP_OPTION: 'pickup',
    EAT_IN_OPTION: 'eat_in',
    PAYMENT: {
        SINPE: 'sinpe',
        CARD: 'card',
        CASH: 'cash'
    },
    PAYMENT_MAPPER: {
        sinpe: 'SINPE Móvil',
        card: 'Tarjeta',
        cash: 'Efectivo'
    },
    DELIVERY_MAPPER: {
        express: 'Express',
        pickup: 'Para Llevar',
        eat_in: 'Comer en el local'
    },
    // This object contains all data from all forms
    USER: {
        NAME: 'userName',
        PHONE: 'userPhone',
        PAYMENT_METHOD: 'userPaymentMethod',
        SINPE_BANK: 'userSinpeBank',
        DELIVERY: 'userDelivery',
        ADDRESS: 'userAddress',
        LOCATION: 'userLocation',
        ADDRESS_REFERENCES: 'userAddressReferences',
        ADDRESS_COORDS: 'userAddressCoords',
        ADDRESS_DISTANCE: 'userAddressDistance',
        ADDRESS_DURATION: 'userAddressDuration',
        TABLE_NUMBER: 'userTableNumber'
    },
    USER_FORM: {
        NAME: 'userName',
        DELIVERY: 'userDelivery',
        PHONE: 'userPhone',
        TABLE_NUMBER: 'userTableNumber',
        PAYMENT_METHOD: 'userPaymentMethod'
    },
    ADDRESS_FORM: {
        ADDRESS_REFERENCES: 'userAddressReferences',
        ADDRESS_COORDS: 'userAddressCoords',
        ADDRESS_DISTANCE: 'userAddressDistance',
        ADDRESS_DURATION: 'userAddressDuration'
    },
    PAYMENT_FORM: {
        PAYMENT_METHOD: 'userPaymentMethod'
    },
    ADDRESS_CONTACT_FORM: {
        ADDRESS: 'userAddress'
    },
    EXPRESS_REQUIRED: [
        'userName',
        'userPhone',
        'userPaymentMethod',
        'userSinpeBank',
        'userDelivery',
        'userAddress',
        'userLocation',
        'userAddressReferences',
        'userAddressCoords',
        'userAddressDistance'
    ],
    PICKUP_REQUIRED: [
        'userName',
        'userPhone',
        'userPaymentMethod',
        'userDelivery',
        'userSinpeBank'
    ],
    EAT_IN_REQUIRED: ['userName', 'userDelivery', 'userTableNumber'],
    ADDRESS_FORM_REQUIRED: ['userAddressReferences'],
    ADD_TO_CART_MODAL: 'AddToCart',
    PLACE_ORDER_MODAL: 'PlaceOrder',
    SELECT_RESTAURANT_MODAL: 'SelectRestaurant',
    SINPE_REDIRECT_MODAL: 'SinpeRedirect',
    DEFAULT_SUBDOMAIN: 'demo',
    GOOGLE_API_KEY: 'AIzaSyBgD2bxuHErLf2yHUE3CJsgblwANvtc_To',
    STATIC_MAP_URL: 'https://maps.googleapis.com/maps/api/staticmap',
    ERRORS: {
        OUT_OF_BOUNDS: 'OUT_OF_BOUNDS'
    },
    MIXPANEL_TOKEN: 'afdbf2a9761fbecb245f3e38da5f7c59',
    BITLY_TOKEN: 'e4cef759db8fcb2f5754064edcffea94141c3003',
    BITLY_SHORTEN_URL: 'https://api-ssl.bitly.com/v4/shorten',
    SINPE_PHONES: {
        BAC: '+1222',
        'Banco Nacional': '+2627',
        'Banco de Costa Rica': '+2276',
        'Banco Lafise': '+9091',
        'Mutual Alajuela': '+50670707079',
        'Banco Promerica': '+50662232450',
        Coopealianza: '+50662229523'
    },
    DEFAULT_BANK: 'BAC',
    CDN_URL_PROD: 'https://cdn.appetit.menu',
    CDN_URL_DEV: 'http://localhost:3000',
    // API_URL_DEV: 'http://localhost:8888',
    API_URL_DEV: 'https://api-dev.appetit.menu',
    API_URL_QA: 'https://api-qa.appetit.menu',
    API_URL_PRD: 'https://api.appetit.menu',
    ITEM_STATUS: {
        available: 'available',
        unavailable: 'unavailable',
        sold_out: 'sold_out'
    }
};
