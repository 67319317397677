import React from 'react';
import { toCurrency } from '../../../utils/utilities';

const OrderCheckoutItem = ({ item }) => {
    return (
        <li className="items-list-checkout">
            <span className="quantity-modal">{`${item.quantity}x`}</span>
            <span className="name-modal">{`${item.name}`}</span>
            <span className="price-modal">{toCurrency(item.subtotal)}</span>
        </li>
    );
};

export default OrderCheckoutItem;
