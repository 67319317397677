import React from 'react';
import SocialLink from './SocialLink';

const SocialList = ({ social }) => {
    const sanitizedList = Object.keys(social).filter((s) => !!social[s]);
    const socialList = sanitizedList.map((key, index) => (
        <SocialLink key={index} sn={key} url={social[key]} />
    ));
    return (
        <div id="social-header">
            <ul className="social-list clearfix">{socialList}</ul>
        </div>
    );
};

export default SocialList;
