import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleOpenShoppingCart } from 'js/stores/app/actions';

const ShoppingCartButton = () => {
    const { shoppingCartOpen } = useSelector((state) => state.app);
    const { items } = useSelector((state) => state.shoppingCart);
    const dispatch = useDispatch();
    const badgeActive = items.length > 0 ? 'badge-active' : '';
    if (items.length === 1) {
        const bodyClassList = document.body.classList;
        bodyClassList.add('first-item');
    }
    return (
        <div
            id="shopping-cart-button"
            onClick={() => dispatch(handleOpenShoppingCart(!shoppingCartOpen))}
        >
            <div className="cursor">
                <div id="nav-button">
                    <span className={`badge ${badgeActive}`}>{items.length}</span>
                    <i className="fa fa-shopping-cart"></i>
                </div>
            </div>
        </div>
    );
};

export default ShoppingCartButton;
