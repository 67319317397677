import { trackPage } from './googleAnalytics';
let currentPage = '';

export const googleAnalytics = (store) => (next) => (action) => {
    if (action.type === '@@router/LOCATION_CHANGE' && window.ga_init) {
        const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;

        if (currentPage !== nextPage) {
            currentPage = nextPage;
            trackPage(nextPage);
        }
    }

    return next(action);
};
