import { createAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { showAppModal } from '../modal/actions';
import * as cartUtils from 'js/utils/cartUtils';
import * as Tracker from 'js/utils/tracker';
import Constants from 'js/utils/constants';
import { cleanContactErrors } from 'js/stores/order/actions';

export const addItemToCart = createAction(
    'ADD_ITEM_TO_CART',
    (item, total, subtotal, deliveryFee, otherCharges) => {
        return {
            payload: {
                total,
                subtotal,
                item,
                deliveryFee,
                otherCharges
            }
        };
    }
);

export const removeCartItem = createAction(
    'REMOVE_CART_ITEM',
    (index, total, subtotal, deliveryFee, otherCharges) => {
        return {
            payload: {
                total,
                index,
                subtotal,
                deliveryFee,
                otherCharges
            }
        };
    }
);

export const updateCartItem = createAction(
    'UPDATE_CART_ITEM',
    (item, total, subtotal, deliveryFee, otherCharges, index) => {
        return {
            payload: {
                total,
                subtotal,
                deliveryFee,
                item,
                otherCharges,
                index
            }
        };
    }
);

export const updateUserDefaults = createAction('UPDATE_USER_DEFAULTS');

export const cleanShoppingCart = createAction('CLEAN_SHOPPING_CART');

export const setInitialCart = createAction('SET_INITIAL_CART');

export const setInitialUserDefaults = createAction('SET_INITIAL_USER_DEFAULTS');

export const handleAddItemToCart = (item, modalMode = false) => {
    return (dispatch, getState) => {
        const { shoppingCart, companyData } = getState();
        const { selectedRestaurant } = companyData;
        const newItem = { ...item, cartId: uuidv4() };
        const updatedItems = [...shoppingCart.items, newItem];
        let tmpCart = {
            ...shoppingCart,
            items: updatedItems
        };
        const total = cartUtils.calculateCartTotal(tmpCart, companyData);
        const subtotal = cartUtils.calculateCartSubtotal(tmpCart);
        const otherCharges = cartUtils.calculateOtherCharges(tmpCart, companyData);
        const deliveryFee = cartUtils.getLocationDeliveryFee(tmpCart, companyData, subtotal);
        tmpCart = {
            ...shoppingCart,
            items: updatedItems,
            total,
            subtotal,
            otherCharges,
            deliveryFee
        };
        cartUtils.updateShoppingCartLS(tmpCart, selectedRestaurant.id);
        Tracker.registerCartItem(newItem, 'add');
        dispatch(addItemToCart(newItem, total, subtotal, deliveryFee, otherCharges));
        if (modalMode) {
            dispatch(showAppModal(false, Constants.ADD_TO_CART_MODAL, {}));
        }
    };
};

export const handleRemoveCartItem = (item) => {
    return (dispatch, getState) => {
        const { shoppingCart, companyData } = getState();
        const { selectedRestaurant } = companyData;
        let index = shoppingCart.items.findIndex((i) => i.cartId === item.cartId);
        if (!index) {
            index = shoppingCart.items.findIndex((i) => i.id === item.id);
        }
        const updatedItems = [
            ...shoppingCart.items.slice(0, index),
            ...shoppingCart.items.slice(index + 1)
        ];
        let tmpCart = {
            ...shoppingCart,
            items: updatedItems
        };
        const total = cartUtils.calculateCartTotal(tmpCart, companyData);
        const subtotal = cartUtils.calculateCartSubtotal(tmpCart);
        const otherCharges = cartUtils.calculateOtherCharges(tmpCart, companyData);
        const deliveryFee = cartUtils.getLocationDeliveryFee(tmpCart, companyData, subtotal);
        tmpCart = {
            ...shoppingCart,
            items: updatedItems,
            total,
            subtotal,
            otherCharges,
            deliveryFee
        };
        cartUtils.updateShoppingCartLS(tmpCart, selectedRestaurant.id);
        Tracker.registerCartItem(item, 'remove');
        dispatch(removeCartItem(index, total, subtotal, deliveryFee, otherCharges));
        dispatch(showAppModal(false, Constants.ADD_TO_CART_MODAL, {}));
    };
};

export const handleEditShoppingCartItem = (item) => {
    return (dispatch, getState) => {
        const { shoppingCart, companyData, modal } = getState();
        const { selectedRestaurant } = companyData;
        let index = shoppingCart.items.findIndex((i) => i.cartId === item.cartId);
        if (!index) {
            index = shoppingCart.items.findIndex((i) => i.id === item.id);
        }
        const updatedItems = [
            ...shoppingCart.items.slice(0, index),
            item,
            ...shoppingCart.items.slice(index + 1)
        ];
        let tmpCart = {
            ...shoppingCart,
            items: updatedItems
        };
        const total = cartUtils.calculateCartTotal(tmpCart, companyData);
        const subtotal = cartUtils.calculateCartSubtotal(tmpCart);
        const otherCharges = cartUtils.calculateOtherCharges(tmpCart, companyData);
        const deliveryFee = cartUtils.getLocationDeliveryFee(tmpCart, companyData, subtotal);
        tmpCart = {
            ...shoppingCart,
            items: updatedItems,
            total,
            subtotal,
            otherCharges,
            deliveryFee
        };
        cartUtils.updateShoppingCartLS(tmpCart, selectedRestaurant.id);
        dispatch(updateCartItem(item, total, subtotal, deliveryFee, otherCharges, index));
        modal.open && dispatch(showAppModal(false, Constants.ADD_TO_CART_MODAL, {}));
    };
};

export const handleUserDefaultInput = (event) => {
    const { name, value } = event.target;
    let maskedValue = null;
    return (dispatch, getState) => {
        const { shoppingCart, companyData } = getState();
        const { selectedRestaurant } = companyData;

        if (name === Constants.USER.PHONE) {
            maskedValue = cartUtils.phoneMask(value);
        }

        if (name === Constants.USER.DELIVERY || name === Constants.USER.PAYMENT_METHOD) {
            Tracker.registerCheckoutOption(name, value, 2);
        }

        let tmpShoppingCart = {
            ...shoppingCart,
            [name]: maskedValue !== null ? maskedValue : value
        };

        let total = shoppingCart.total,
            deliveryFee = shoppingCart.deliveryFee,
            otherCharges = cartUtils.calculateOtherCharges(tmpShoppingCart, companyData);

        if (name === Constants.USER.DELIVERY || name === Constants.USER.ADDRESS_COORDS) {
            total = cartUtils.calculateCartTotal(tmpShoppingCart, companyData);
            deliveryFee = cartUtils.getLocationDeliveryFee(tmpShoppingCart, companyData);
        }

        tmpShoppingCart = {
            ...tmpShoppingCart,
            total,
            deliveryFee,
            otherCharges
        };

        cartUtils.updateShoppingCartLS(tmpShoppingCart, selectedRestaurant.id);
        dispatch(updateUserDefaults(tmpShoppingCart));
        dispatch(cleanContactErrors());
    };
};

export const handleUserDefaultForm = (form) => {
    let maskedValue = null;
    return (dispatch, getState) => {
        const { shoppingCart, companyData } = getState();
        const { selectedRestaurant } = companyData;
        let tmpShoppingCart = {
            ...shoppingCart
        };
        Object.keys(form).forEach((formKey) => {
            let name = formKey,
                value = form[formKey];

            if (name === Constants.USER.PHONE) {
                maskedValue = cartUtils.phoneMask(value);
            }

            if (name === Constants.USER.DELIVERY || name === Constants.USER.PAYMENT_METHOD) {
                Tracker.registerCheckoutOption(name, value, 2);
            }

            tmpShoppingCart[name] = maskedValue !== null ? maskedValue : value;
        });

        tmpShoppingCart.total = cartUtils.calculateCartTotal(tmpShoppingCart, companyData);
        tmpShoppingCart.deliveryFee = cartUtils.getLocationDeliveryFee(
            tmpShoppingCart,
            companyData,
            cartUtils.calculateCartSubtotal(tmpShoppingCart)
        );
        tmpShoppingCart.otherCharges = cartUtils.calculateOtherCharges(
            tmpShoppingCart,
            companyData
        );

        cartUtils.updateShoppingCartLS(tmpShoppingCart, selectedRestaurant.id);
        dispatch(updateUserDefaults(tmpShoppingCart));
        dispatch(cleanContactErrors());
    };
};
