import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useAccordionToggle } from 'react-bootstrap';
import CollapsedCard from './CollapsedCard';
import OrderCheckoutButton from 'js/components/placeOrderModal/components/OrderCheckoutButton';
import Constants from 'js/utils/constants';
import BackButton from 'js/components/common/BackButton';

let classes = {};

const OrderPayment = ({ actions: { setActiveKey, handleUserDefaultForm } }) => {
    const { companyData, shoppingCart } = useSelector((state) => state);
    const toggleOrderDetails = useAccordionToggle('0', () => setActiveKey('0'));
    const paymentMethods = companyData.selectedRestaurant.payment_methods;
    const [form, setForm] = useState({
        userPaymentMethod: shoppingCart.userPaymentMethod || paymentMethods[0],
        userSinpeBank: shoppingCart.userSinpeBank || Constants.DEFAULT_BANK
    });
    const goBack = (e) => {
        setForm({
            userPaymentMethod: shoppingCart.userPaymentMethod || paymentMethods[0],
            userSinpeBank: shoppingCart.userSinpeBank
        });
        toggleOrderDetails(e);
    };
    const trackForm = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };
    const handleSavePaymentMethod = () => {
        handleUserDefaultForm(form);
        toggleOrderDetails();
    };
    const cards = paymentMethods.map((paymentMethod, key) => {
        const options = {
            paymentMethod,
            companyData,
            value: form.userPaymentMethod,
            handler: trackForm
        };
        const Component = classes[paymentMethod];
        return (
            <CollapsedCard key={key} {...options}>
                <Component
                    bank={form.userSinpeBank}
                    handler={trackForm}
                    companyData={companyData}
                />
            </CollapsedCard>
        );
    });
    return (
        <>
            <BackButton goBack={goBack} />
            <h5>Seleccioná tu método de pago</h5>
            <div className="container">
                <div className="row no-gutters add-to-cart select-payment">
                    <div className="col-12"></div>
                    <div className="col-12">
                        <div className="row btn-container-modal">
                            <div className="col-12">{cards}</div>
                        </div>
                    </div>
                </div>
                <div className="save-address">
                    <OrderCheckoutButton
                        nextStep={handleSavePaymentMethod}
                        title={'Guardar Método de Pago'}
                    />
                </div>
            </div>
        </>
    );
};

OrderPayment.propTypes = {
    actions: PropTypes.object
};

export const paymentMethodsFactory = (_classes) => {
    classes = _classes;
};

export default OrderPayment;
