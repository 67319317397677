import { v4 as uuidv4 } from 'uuid';
import * as Utilities from './utilities';

export const getUserSession = () => {
    let userSession = Utilities.getLocalStorageObject('current_user') || {};
    if (!Object.keys(userSession).length) {
        userSession.sessionId = uuidv4();
        Utilities.setLocalStorage('current_user', userSession);
    }

    return userSession.sessionId;
};
