import { createAction } from '@reduxjs/toolkit';

export const showSinpeRedirectModal = createAction(
    'SHOW_REDIRECT_MODAL',
    ({ sinpeRedirectModalOpen }) => {
        return {
            payload: {
                sinpeRedirectModalOpen
            }
        };
    }
);

export const showSinpeRedirectModalDesktop = createAction(
    'SHOW_REDIRECT_MODAL_DESKTOP',
    ({ sinpeRedirectModalDesktopOpen }) => {
        return {
            payload: {
                sinpeRedirectModalDesktopOpen
            }
        };
    }
);
