import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showAppModal } from 'js/stores/modal/actions';
import Constants from 'js/utils/constants';

const Button = () => {
    const { restaurants, selectedRestaurant } = useSelector((state) => state.companyData);
    const dispatch = useDispatch();
    if (restaurants.length === 1) {
        return null;
    }
    return (
        <div
            id="shopping-cart-button"
            onClick={() =>
                dispatch(
                    showAppModal(
                        true,
                        Constants.SELECT_RESTAURANT_MODAL,
                        {},
                        { modalFull: false, closeButton: false }
                    )
                )
            }
        >
            <div className="cursor">
                <div id="nav-button">
                    <span className="label-rest-op">{selectedRestaurant.name}</span>
                    <i className="fa fa-angle-down"></i>
                </div>
            </div>
        </div>
    );
};

export default Button;
