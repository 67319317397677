import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Constants from 'js/utils/constants';
import { toCurrency } from 'js/utils/utilities';

const ShoppingCartItem = ({ item, displayModalWithItem }) => {
    const openModal = () => {
        displayModalWithItem(true, Constants.ADD_TO_CART_MODAL, {
            item,
            action: Constants.EDIT,
            showModal: true
        });
    };

    return (
        <li onClick={openModal}>
            <div className="menu-item">
                <div className="row">
                    <div className="col-12">
                        <h5>
                            <span>{item.quantity}</span>
                            {item.name} - {toCurrency(item.price)}
                        </h5>
                        {item.orderNotes && (
                            <p>
                                Notas:&nbsp;
                                <span className="">{item.orderNotes}</span>
                            </p>
                        )}
                        {item.modifiers &&
                            _.sortBy(item.modifiers, ['sort_order'])
                                .filter((modifier) => modifier.items.some((i) => i.checked))
                                .map((modifier) => (
                                    <div key={modifier.sort_order}>
                                        {/* <strong>
                                            {modifier.title}
                                            {modifier.subtotal
                                                ? ` (${toCurrency(modifier.subtotal)})`
                                                : ''}
                                            :
                                        </strong> */}
                                        <ul>
                                            {_.filter(modifier.items, ['checked', true]).map(
                                                (i) => (
                                                    <li key={i.sort_order}>
                                                        {i.name}{' '}
                                                        <span className="modifier-price">
                                                            {i.price > 0 &&
                                                                `(${toCurrency(i.price)})`}
                                                        </span>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                ))}
                        <ul className="mt-2">
                            <li>
                                <span>Subtotal: {toCurrency(item.subtotal)}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </li>
    );
};

ShoppingCartItem.propTypes = {
    item: PropTypes.object.isRequired,
    displayModalWithItem: PropTypes.func.isRequired
};

export default ShoppingCartItem;
