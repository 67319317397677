import React from 'react';
import PropTypes from 'prop-types';
import { toCurrency } from '../../../utils/utilities';

const Item = ({ item }) => {
    return (
        <div className="col-12 col-lg-6 col-md-6 col-sm-12">
            <div className="menu-item">
                <h5>{item.name}</h5>
                <span className="price">{toCurrency(item.price)}</span>
                <p>{item.description}</p>
            </div>
        </div>
    );
};

Item.propTypes = {
    item: PropTypes.object
};

export default Item;
