import Constants from './constants';
import * as OrderUtils from './orderUtils';
import isMobile from './isMobile';
import { zoneContainsLocation } from './mapUtils';

export const getFormType = (key) => {
    switch (key) {
        case Constants.USER.DELIVERY:
            return 'select';
        case Constants.USER.PHONE:
        case Constants.USER.TABLE_NUMBER:
            return 'tel';
        case Constants.USER.ADDRESS_COORDS:
        case Constants.USER.ADDRESS_DISTANCE:
        case Constants.USER.ADDRESS_DURATION:
            return 'hidden';
        default:
            return 'text';
    }
};

export const getFieldClass = (key) => {
    switch (key) {
        case Constants.USER.ADDRESS:
        case Constants.USER.DELIVERY:
        case Constants.USER.PAYMENT_METHOD:
            return 'selectable-row next';
        default:
            return '';
    }
};

export const getFormLabels = (key) => {
    switch (key) {
        case Constants.USER.PAYMENT_METHOD:
            return 'Método de pago';
        case Constants.USER.DELIVERY:
            return 'Opciones de entrega';
        case Constants.USER.PHONE:
            return 'Su Teléfono';
        case Constants.USER.FORMATED_ADDRESS:
        case Constants.USER.ADDRESS:
            return 'Su Dirección';
        case Constants.USER.LOCATION:
            return 'Ubicación';
        case Constants.USER.ADDRESS_REFERENCES:
            return 'Otras Señas, ej: 2da casa a mano derecha, muro verde';
        case Constants.USER.NAME:
            return 'Su Nombre';
        case Constants.USER.TABLE_NUMBER:
            return 'Número de Mesa';
        default:
            return 'getFormLabels';
    }
};

export const getFormReadonly = (key) => {
    switch (key) {
        case Constants.USER.PAYMENT_METHOD:
        case Constants.USER.ADDRESS:
            return true;
        default:
            return false;
    }
};

export const getFormDisabled = (key, data) => {
    switch (key) {
        case Constants.USER.ADDRESS:
            if (!OrderUtils.isExpress(data)) {
                return true;
            }
            return false;
        case Constants.USER.PAYMENT_METHOD:
            if (OrderUtils.isEatIn(data)) {
                return true;
            }
            return false;
        default:
            return false;
    }
};

export const getFormHidden = (key, data) => {
    switch (key) {
        case Constants.USER.TABLE_NUMBER:
            if (!OrderUtils.isEatIn(data)) {
                return true;
            }

            return false;
        case Constants.USER.PHONE:
            if (OrderUtils.isEatIn(data)) {
                return true;
            }

            return false;
        default:
            return false;
    }
};

export const getFormOptions = (key, data) => {
    switch (key) {
        case Constants.USER.PAYMENT_METHOD:
            const paymentOptions = {};
            data.selectedRestaurant.payment_methods.forEach((option) => {
                paymentOptions[option] = Constants.PAYMENT_MAPPER[option];
            });
            return paymentOptions;
        case Constants.USER.DELIVERY:
            const deliveryOptions = {};
            data.selectedRestaurant.delivery_options.forEach((option) => {
                deliveryOptions[option] = Constants.DELIVERY_MAPPER[option];
            });
            return deliveryOptions;
        default:
            return [];
    }
};

export const getFormValue = (key, data) => {
    switch (key) {
        case Constants.USER.PAYMENT_METHOD:
            return Constants.PAYMENT_MAPPER[data[key]];
        default:
            return data[key];
    }
};

export const getFormIcon = (key, value) => {
    switch (key) {
        case Constants.USER.DELIVERY:
            if (value === Constants.EXPRESS_OPTION) {
                return 'motorcycle';
            } else if (value === Constants.EAT_IN_OPTION) {
                return 'building';
            } else {
                return 'shopping-bag';
            }
        case Constants.USER.PHONE:
            return 'phone';
        case Constants.USER.ADDRESS:
            return 'map-marker';
        case Constants.USER.PAYMENT_METHOD:
            if (value === Constants.PAYMENT.CARD) {
                return 'credit-card';
            } else if (value === Constants.PAYMENT.SINPE) {
                return 'mobile';
            } else {
                return 'money';
            }
        case Constants.USER.TABLE_NUMBER:
            return 'list-ol';
        default:
            return 'user';
    }
};

export const getUserDefaultsKeys = (userDefaults) => {
    return Object.keys(Constants.USER)
        .map((key) => Constants.USER[key])
        .filter((key) => keyIsAddress(key, userDefaults) || keyIsNotAddress(key, userDefaults));
};

export const getOrderFormKeys = () => {
    return Object.keys(Constants.USER_FORM).map((key) => Constants.USER_FORM[key]);
};

export const getAddressContactFormKeys = () => {
    return Object.keys(Constants.ADDRESS_CONTACT_FORM).map(
        (key) => Constants.ADDRESS_CONTACT_FORM[key]
    );
};

export const getAddressFormKeys = () => {
    return Object.keys(Constants.ADDRESS_FORM).map((key) => Constants.ADDRESS_FORM[key]);
};

export const formIsValid = (form, companyData) => {
    return !Object.keys(validateUserDefaults(form, companyData)).length;
};

export const addressFormIsValid = (form, companyData) => {
    return !Object.keys(validateAddressForm(form, companyData)).length;
};

export const validateAddressForm = (form, companyData) => {
    const formRequired = getAddressFormRequired(form);
    let errors = validateRequiredFields(formRequired);
    validateLocation(errors, form, Constants.USER.ADDRESS_COORDS, companyData);
    validateMaxDistance(errors, form, Constants.USER.ADDRESS_REFERENCES, companyData);
    return errors;
};

export const getAddressFormRequired = (form) => {
    let requiredInputs = {};
    Object.keys(form).forEach((key) => {
        if (Constants.ADDRESS_FORM_REQUIRED.includes(key)) {
            requiredInputs[key] = form[key];
        }
    });
    return requiredInputs;
};

export const getUserDefaultsFormRequired = (form) => {
    let requiredInputs = {};
    let requiredFields = {};
    if (OrderUtils.isExpress(form)) {
        requiredFields = Constants.EXPRESS_REQUIRED;
    } else if (OrderUtils.isEatIn(form)) {
        requiredFields = Constants.EAT_IN_REQUIRED;
    } else {
        requiredFields = Constants.PICKUP_REQUIRED;
    }
    Object.keys(Constants.USER).forEach((key) => {
        const constantsKey = Constants.USER[key];
        if (requiredFields.includes(constantsKey)) {
            requiredInputs[constantsKey] = form[constantsKey];
        }
    });
    return requiredInputs;
};

export const validateUserDefaults = (form, companyData) => {
    const formRequired = getUserDefaultsFormRequired(form);
    let errors = validateRequiredFields(formRequired);
    validatePhoneNumberLength(errors, form);
    if (OrderUtils.isExpress(form)) {
        validateMaxDistance(errors, form, Constants.USER.ADDRESS, companyData);
        validateLocation(errors, form, Constants.USER.ADDRESS, companyData);
        validateAddressValues(errors, form);
    }
    return errors;
};

export const validateAddressValues = (errors, form) => {
    if (errors[Constants.USER.ADDRESS]) {
        return;
    }
    Object.keys(Constants.ADDRESS_FORM).forEach((key) => {
        const constantsKey = Constants.ADDRESS_FORM[key];
        if (!form[constantsKey]) {
            switch (constantsKey) {
                case Constants.USER.ADDRESS_COORDS:
                    errors[Constants.USER.ADDRESS] = 'Debe seleccionar un punto en el mapa.';
                    break;
                case Constants.USER.ADDRESS_DISTANCE:
                    errors[Constants.USER.ADDRESS] =
                        'No se pudo obtener la distancia desde el restaurante.';
                    break;
                case Constants.USER.ADDRESS_REFERENCES:
                    errors[Constants.USER.ADDRESS] = 'Debe ingresar un punto de referencia.';
                    break;
                default:
                    return;
            }
        }
    });
};

export const validateRequiredFields = (form) => {
    const errors = {};
    Object.keys(form)
        .filter((key) => (form[key] || form[key] === 0 ? false : true))
        .forEach((key) => {
            const isSinpe = OrderUtils.isSinpe(form[Constants.USER.PAYMENT_METHOD]),
                isBankKey = key === 'userSinpeBank';
            if (isSinpe && isBankKey) {
                if (isMobile()) {
                    errors[Constants.USER.PAYMENT_METHOD] = `Seleccione un banco.`;
                }
            } else {
                errors[key] = `Campo requerido.`;
            }
        });

    return errors;
};

export const validateMaxDistance = (errors, form, key, companyData) => {
    if (errors[key]) {
        return;
    }
    const { selectedRestaurant } = companyData;
    if (selectedRestaurant.delivery_zones) {
        const { delivery_zones: deliveryZones } = selectedRestaurant;
        const userLocation = form[Constants.USER.ADDRESS_COORDS];
        const notInDeliveryZones =
            deliveryZones &&
            (!deliveryZones.length ||
                !deliveryZones.some((zone) => zoneContainsLocation(zone, userLocation)));

        if (notInDeliveryZones) {
            errors[key] = 'La dirección está fuera del área de entregas';
            return;
        }
    } else {
        // remove this once we migrate all business to delivery zones
        const maxDistance = selectedRestaurant.delivery_config.max_distance;
        const distance = form[Constants.USER.ADDRESS_DISTANCE];
        if (distance > maxDistance) {
            errors[key] = 'La dirección está fuera del área de entregas';
        }
    }
};

export const validateLocation = (errors, form, key, companyData) => {
    const message = 'Por favor seleccione su ubicación en el mapa';
    if (errors[key]) {
        return;
    } else if (!form[Constants.USER.ADDRESS_COORDS]) {
        errors[key] = message;
        return;
    }

    const restaurantLocation = companyData.selectedRestaurant.address.point;
    const userLocation = form[Constants.USER.ADDRESS_COORDS];

    if (
        restaurantLocation.lat === userLocation.lat &&
        restaurantLocation.lng === userLocation.lng
    ) {
        errors[key] = message;
    }
};

export const keyIsAddress = (key, userDefaults) => {
    return (
        userDefaults[Constants.USER.DELIVERY] === Constants.EXPRESS_OPTION &&
        (key === Constants.USER.ADDRESS || key === Constants.USER.LOCATION)
    );
};

export const keyIsNotAddress = (key) => {
    return key !== Constants.USER.ADDRESS && key !== Constants.USER.LOCATION;
};

export const validatePhoneNumberLength = (errors, form) => {
    if (
        form[Constants.USER.PHONE] &&
        form[Constants.USER.PHONE].length < 9 &&
        !errors[Constants.USER.PHONE]
    ) {
        errors[Constants.USER.PHONE] = `${getFormLabels(
            Constants.USER.PHONE
        )} debe contener 8 dígitos.`;
    }
};
