import { createAction } from '@reduxjs/toolkit';
import * as FormUtils from '../../utils/formUtils';
import { handleUserDefaultForm } from '../shoppingCart/actions';
import { cleanContactErrors } from '../order/actions';
import * as Tracker from '../../utils/tracker';

export const setAddressErrors = createAction('SET_ADDRESS_ERRORS');
export const cleanAddressErrors = createAction('CLEAN_ADDRESS_ERRORS');

export const handleSaveAddress = (form, callback) => {
    return (dispatch, getState) => {
        const { companyData } = getState();
        if (!FormUtils.addressFormIsValid(form, companyData)) {
            dispatch(handleValidateAddressForm(form));
            return;
        }

        dispatch(cleanAddressErrors());
        dispatch(cleanContactErrors());
        dispatch(handleUserDefaultForm(form));
        Tracker.registerSaveAddress(form);
        callback();
    };
};

export const handleValidateAddressForm = (form) => {
    return (dispatch, getState) => {
        const { companyData } = getState();
        const errors = FormUtils.validateAddressForm(form, companyData);
        if (Object.keys(errors).length) {
            dispatch(setAddressErrors(errors));
            return;
        }
    };
};
