import { useContext } from 'react';
import ScrollableMenuContext from '../ScrollableMenuContext';

const useScrollableContext = () => {
    const context = useContext(ScrollableMenuContext);
    if (!context) {
        throw new Error('No Context');
    }

    return context;
};

export default useScrollableContext;
