import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import * as DomUtils from './utils/domUtils';
import Navigator from './components/Navigator';
import NavigatorItem from './components/NavigatorItem';
import Container from './components/Container';
import Section from './components/Section';
import ScrollableMenuContext from './ScrollableMenuContext';

// import './styles/ScrollableMenu.scss';

const ScrollableMenu = ({ children }) => {
    const scrollByClick = useRef(false);
    const sections = useRef(null);
    const animating = useRef(false);

    const handleNavBarClick = useCallback((event) => {
        event.preventDefault();
        const { target } = event;
        scrollByClick.current = true;
        DomUtils.animateMenuNavegation(target, sections, () => {
            scrollByClick.current = false;
        });
    }, []);

    const handleScroll = () => {
        if (!scrollByClick.current) {
            DomUtils.isSelected(sections, (section) => {
                if (!animating.current) {
                    animating.current = true;
                    DomUtils.scrollNavbar(`#${section.attr('id')}`, 100, () => {
                        animating.current = false;
                    });
                }
            });
        }
    };

    useEffect(() => {
        sections.current = $('a[href^="#"]');
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <ScrollableMenuContext.Provider value={{ clickHandler: handleNavBarClick }}>
            <section className="section section-digitalmenu scrollable-menu">{children}</section>
        </ScrollableMenuContext.Provider>
    );
};

ScrollableMenu.propTypes = {
    children: PropTypes.node.isRequired
};

ScrollableMenu.Navigator = Navigator;
ScrollableMenu.NavigatorItem = NavigatorItem;
ScrollableMenu.Container = Container;
ScrollableMenu.Section = Section;

export default ScrollableMenu;
