import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ItemForm from './ItemForm';

const ItemDetails = ({ modalMode, handler, actions }) => {
    const companySettings = useSelector((state) => state.companyData.settings);
    const { showingItem, action } = useSelector((state) => state.itemDetails);
    const handleAddToCart = (item) => {
        handler(item, modalMode);
    };
    return (
        <div className="item-details">
            {companySettings.supports_menu_photos && !!showingItem.image && (
                <section className="section header-section header-item">
                    <img src={showingItem.image} alt="" />
                </section>
            )}
            <section className="section item-content">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="menu-item">
                            <div className="row add-to-cart">
                                {modalMode && <h3 className="text-center">{showingItem.name}</h3>}
                                <div className="col-12">
                                    <div>
                                        <p className="item-description">
                                            {showingItem.description}
                                        </p>
                                    </div>
                                    <ItemForm
                                        item={showingItem}
                                        action={action}
                                        modalMode={modalMode}
                                        actions={actions}
                                        handleAddToCart={handleAddToCart}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

ItemDetails.defaultProps = {
    modalMode: false
};

ItemDetails.propTypes = {
    handler: PropTypes.func.isRequired,
    actions: PropTypes.object,
    modalMode: PropTypes.bool
};

export default ItemDetails;
