import React from 'react';
import PropTypes from 'prop-types';
import FieldControl from 'js/components/common/FieldControl';
import Constants from 'js/utils/constants';

const Sinpe = ({ bank, handler, companyData }) => {
    return (
        <React.Fragment>
            <p>Seleccioná el banco con el que enviás los SINPE.</p>
            <div className="select-sinpe">
                <FieldControl
                    type={'select'}
                    name={Constants.USER.SINPE_BANK}
                    value={bank || Constants.DEFAULT_BANK}
                    handler={handler}
                    options={{
                        BAC: 'BAC',
                        'Banco Nacional': 'Banco Nacional',
                        'Banco de Costa Rica': 'Banco de Costa Rica',
                        'Banco Lafise': 'Banco Lafise',
                        'Mutual Alajuela': 'Mutual Alajuela',
                        'Banco Promerica': 'Banco Promerica',
                        Coopealianza: 'Coopealianza',
                        Otro: 'Otro'
                    }}
                />
            </div>
            {bank === 'Otro' && (
                <div className="row footer-note">
                    <small className="form-text">
                        <strong>*Importante</strong>: Enviar SINPE Móvil al{' '}
                        {companyData.selectedRestaurant.sinpe_number} a nombre de{' '}
                        {companyData.selectedRestaurant.sinpe_name} y enviar comprobante por medio
                        de WhatsApp después de finalizar el pedido por el app.
                    </small>
                </div>
            )}
        </React.Fragment>
    );
};

Sinpe.propTypes = {
    bank: PropTypes.string,
    handler: PropTypes.func,
    companyData: PropTypes.object
};

export default Sinpe;
