import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './js/components/app';
import * as serviceWorker from './js/utils/serviceWorker';
import store from './js/initializers/createStore';
import * as Tracker from './js/utils/tracker';
import Constants from './js/utils/constants';

window.__localeId__ = 'es';

Tracker.initializeMixpanel(Constants.MIXPANEL_TOKEN);

document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
