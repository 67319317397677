import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isAndroid } from 'js/utils/isMobile';
import 'styles/base/loading.scss';

const Redirect = ({ handleCloseModal, sinpeHref }) => {
    useEffect(() => {
        let timer;
        handleCloseModal(timer, () => {
            if (isAndroid()) {
                document.getElementById('open-sms').click();
            } else {
                window.location.href = sinpeHref;
            }
        });
        return () => {
            clearTimeout(timer);
        };
    });
    return (
        <>
            <h5 className="center">
                Te estamos redireccionando a tu aplicación de mensajes de texto...
            </h5>
            <div className={isAndroid() ? 'android' : 'ios'}>
                <div className="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <a id="open-sms" href={sinpeHref}>
                Presioná <span>aquí</span> si el navegador no te redirecciona automáticamente.
            </a>
        </>
    );
};

Redirect.propTypes = {
    handleCloseModal: PropTypes.func,
    sinpeHref: PropTypes.string
};

export default Redirect;
