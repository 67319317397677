import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import OrderingRoutes from './OrderingRoutes';
import RestaurantRoutes from './RestaurantRoutes';

const Routes = () => {
    return (
        <LastLocationProvider>
            <Switch>
                <Route exact path="/">
                    <OrderingRoutes />
                </Route>
                <Route exact path="/restaurante">
                    <RestaurantRoutes />
                </Route>
                <Route path="*">
                    <OrderingRoutes />
                </Route>
            </Switch>
        </LastLocationProvider>
    );
};

export default Routes;
