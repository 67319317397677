import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import initialState from 'js/initializers/initialState';
import * as itemActions from './actions';
import {
    calculateItemSubtotal,
    calculateModifierSubtotal,
    isModifierValid,
    isItemValid
} from 'js/utils/cartUtils';

const itemDetails = createReducer(initialState.itemDetails, (builder) => {
    builder
        .addCase(itemActions.showSingleItem, (state, action) => ({
            ...state,
            action: action.payload.action,
            backRoute: action.payload.backRoute,
            showModal: !!action.payload.showModal,
            showingItem: { ...action.payload.item }
        }))
        .addCase(itemActions.updateAddToCartItem, (state, action) => {
            state.showingItem.quantity = action.payload.quantity;
            state.showingItem.subtotal = calculateItemSubtotal(state.showingItem);
        })
        .addCase(itemActions.updateItemOrderNotes, (state, action) => ({
            ...state,
            showingItem: {
                ...state.showingItem,
                orderNotes: action.payload
            }
        }))
        .addCase(itemActions.updateItemModifiers, (state, action) => {
            const { modifier, modifierItem, checked } = action.payload;
            const { showingItem } = state;
            const modifierIndex = _.findIndex(showingItem.modifiers, ['id', modifier.id]);
            const itemIndex = _.findIndex(modifier.items, ['id', modifierItem.id]);
            if (modifier.quantity === 1 && modifier.required) {
                state.showingItem.modifiers[modifierIndex].items.forEach(
                    (item) => (item.checked = false)
                );
            }
            state.showingItem.modifiers[modifierIndex].items[itemIndex].checked = checked;
            state.showingItem.modifiers[modifierIndex].isValid = isModifierValid(
                state.showingItem.modifiers[modifierIndex]
            );
            state.showingItem.modifiers[modifierIndex].subtotal = calculateModifierSubtotal(
                state.showingItem.modifiers[modifierIndex]
            );
            state.showingItem.isValid = isItemValid(state.showingItem);
            state.showingItem.subtotal = calculateItemSubtotal(state.showingItem);
        });
});

export default itemDetails;
