import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import '../../../../styles/base/loading.scss';

const SinpeCheckout = ({ activeKey, actions: { handlePlaceOrder } }) => {
    useEffect(() => {
        let timer;
        if (activeKey === '4') {
            timer = setTimeout(() => {
                handlePlaceOrder();
            }, 9000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [activeKey, handlePlaceOrder]);
    return (
        <React.Fragment>
            <h5 className="center">Enviando orden a WhatsApp...</h5>
            <div className="wa-main">
                <div className="wa-cont">
                    <div className="step1">
                        <div className="wa-img">
                            <p>
                                Para completar el pedido recuerda presionar el botón para Continuar
                                al chat
                            </p>
                            <figure>
                                <img
                                    src="/images/chatea-en-whatsapp.png"
                                    alt="Continuar al Chat"
                                    title="Continuar al Chat"
                                />
                            </figure>
                            <a className="wa-btn" onClick={(e) => e.preventDefault()} href="/">
                                Continuar al Chat
                            </a>
                        </div>
                    </div>
                    <div className="step2">
                        <div className="send-msj">
                            <p>
                                En el chat presiona el botón de Enviar para enviar el mensaje con la
                                orden
                            </p>
                            <img
                                src="/images/enviar-orden.png"
                                alt="Enviar Orden"
                                title="Enviar Orden"
                            />
                            <figure className="send-btn">
                                <img
                                    src="/images/send-btn.png"
                                    alt="Enviar Orden"
                                    title="Enviar Orden"
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

SinpeCheckout.propTypes = {
    activeKey: PropTypes.string,
    actions: PropTypes.object
};

export default SinpeCheckout;
