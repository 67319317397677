import moment from 'moment';
import _ from 'lodash';
import Constants, { NAV_APP } from './constants';
import * as Utilities from './utilities';
import isMobile from './isMobile';
import { shouldApplyOtherCharges } from './cartUtils';

const getOrdersLSKey = (restaurantId) => `${Constants.STORED_ORDERS_LS}_${restaurantId}`;

export const generateOrder = (cart, companyData, preorderId) => {
    const { selectedRestaurant } = companyData;
    const order = {
        id: preorderId ? preorderId : Utilities.randomId(),
        affiliation: `${selectedRestaurant.name} - Online`,
        date: moment().unix(),
        ...cart
    };
    const ordersLsKey = getOrdersLSKey(selectedRestaurant.id);
    const storedOrders = Utilities.getLocalStorageArray(ordersLsKey);
    if (Array.isArray(storedOrders)) {
        storedOrders.push(order);
        Utilities.setLocalStorage(ordersLsKey, storedOrders);
    }

    return order;
};

export const buildWALink = (order, companyData, callback) => {
    const otherBank = order[Constants.USER.SINPE_BANK] === 'Otro';
    const isSINPE = isSinpe(order.userPaymentMethod) && !isEatIn(order);
    const shouldShowSinpeLink = isSINPE && !otherBank && !companyData.settings.disable_sinpe_link;
    if (shouldShowSinpeLink) {
        const businessPhone = companyData.selectedRestaurant.sinpe_number;
        const subdomain = window.location.origin;
        const sinpeLink = `${subdomain}/sinpe-payment?amount=${order.total}&bank=${
            Constants.SINPE_PHONES[order[Constants.USER.SINPE_BANK]]
        }&phone=${
            businessPhone.includes('-') ? businessPhone.replace('-', '') : businessPhone
        }&order=${order.id}`;
        Utilities.sinpeShortenLink(sinpeLink)
            .then((data) => serializeOrder(order, companyData, data.link, callback))
            .catch((fullLink) => {
                serializeOrder(order, companyData, fullLink, callback);
            });
    } else {
        serializeOrder(order, companyData, null, callback);
    }
};

export const serializeOrder = (order, companyData, link, callback) => {
    const otherBank = order[Constants.USER.SINPE_BANK] === 'Otro';
    const isSINPE = isSinpe(order.userPaymentMethod) && !isEatIn(order);
    const shouldShowSinpeLink = isSINPE && !companyData.settings.disable_sinpe_link;

    let clientDetailsText = `🔺 Pedido realizado a través de https://${window.location.hostname}`;
    if (companyData.restaurants.length > 1) {
        clientDetailsText += '\nRestaurante:';
        clientDetailsText += `\n🏬 ${companyData.selectedRestaurant.name}\n`;
    }
    clientDetailsText += '\nCliente:';
    clientDetailsText += `\n👤 ${order.userName}`;
    if (!isEatIn(order)) {
        clientDetailsText += `\n📱 ${order.userPhone}\n`;
    } else {
        clientDetailsText += '\n';
    }

    if (isExpress(order)) {
        clientDetailsText += '\nDirección:';
        if (companyData.settings.navigation_app === NAV_APP.GOOGLE) {
            clientDetailsText += `\n🗺️ https://www.google.com/maps/dir/?api=1&destination=${order.userAddressCoords.lat}%2C${order.userAddressCoords.lng}&travelmode=driving&dir_action=navigate`;
        } else {
            clientDetailsText += `\n🗺️ https://www.waze.com/ul?ll=${order.userAddressCoords.lat}%2C${order.userAddressCoords.lng}&navigate=yes&zoom=17`;
        }
        clientDetailsText += `\n📍${order.userAddress}\n`;
    } else if (isPickup(order)) {
        clientDetailsText += `\n🧳 El cliente pasa al local a recoger el pedido.\n`;
    }

    clientDetailsText += `\nOrden #${order.id}:`;
    if (!isEatIn(order)) {
        if (order.userPaymentMethod === Constants.PAYMENT.SINPE) {
            clientDetailsText += shouldShowSinpeLink
                ? `\n📱${Constants.PAYMENT_MAPPER[order.userPaymentMethod]}`
                : `\n📱${Constants.PAYMENT_MAPPER[order.userPaymentMethod]} - ${
                      order.userSinpeBank
                  }`;
        } else if (order.userPaymentMethod === Constants.PAYMENT.CARD) {
            clientDetailsText += `\n💳 ${Constants.PAYMENT_MAPPER[order.userPaymentMethod]}`;
        } else {
            clientDetailsText += `\n💷 ${Constants.PAYMENT_MAPPER[order.userPaymentMethod]}`;
        }
    }

    if (isExpress(order)) {
        clientDetailsText += `\n🛵 ${Constants.DELIVERY_MAPPER[order.userDelivery]}\n`;
    } else if (isEatIn(order)) {
        clientDetailsText += `\n🍽 Pedido para consumir en el local.`;
        clientDetailsText += `\n🔢 Mesa: ${order.userTableNumber}.\n`;
    } else {
        clientDetailsText += `\n🧳 ${Constants.DELIVERY_MAPPER[order.userDelivery]}\n`;
    }

    order.items.forEach((item) => {
        clientDetailsText += `\n*${item.quantity}x - ${item.name} (${
            item.category
        }) - ${Utilities.toCurrency(item.subtotal)}*`;
        if (item.modifiers && item.modifiers.length) {
            _.sortBy(item.modifiers, 'sort_order')
                .filter((modifier) => modifier.items.some((i) => i.checked))
                .forEach((modifier) => {
                    const itemNamesList = _(modifier.items)
                        .filter(['checked', true])
                        .map('name')
                        .value();
                    const namesList = listToString(itemNamesList);
                    clientDetailsText += `\n\xa0\xa0 *${modifier.title}:* ${namesList}`;
                });
            if (!item.orderNotes) clientDetailsText += '\n';
        }
        if (item.orderNotes) {
            clientDetailsText += `\n\xa0\xa0 *Notas:* ${item.orderNotes}\n`;
        }
    });
    clientDetailsText += `\n----------------------------`;
    clientDetailsText += `\nSubtotal:\xa0${Utilities.toCurrency(order.subtotal)}`;
    if (isExpress(order) && !companyData.settings.disable_calculate_express) {
        clientDetailsText += `\nExpress:\xa0\xa0${Utilities.toCurrency(order.deliveryFee)}`;
    }
    if (shouldApplyOtherCharges(order, companyData)) {
        clientDetailsText += `\nOtros cargos:\xa0${Utilities.toCurrency(order.otherCharges)}`;
    }
    if (isExpress(order) && companyData.settings.disable_calculate_express) {
        clientDetailsText += `\nTotal:\xa0\xa0\xa0\xa0\xa0\xa0\xa0Pendiente de monto de express⚠️`;
    } else {
        clientDetailsText += `\nTotal:\xa0\xa0\xa0\xa0\xa0\xa0\xa0${Utilities.toCurrency(
            order.total
        )}`;
    }
    if (shouldShowSinpeLink) {
        if (link) {
            clientDetailsText += `\n\n*⚠️ ${
                order.userName
            } realiza tu pago por SINPE MÓVIL presionando aquí${
                !isMobile() ? ' desde tu celular' : ''
            }:*`;
            clientDetailsText += `\n${link}`;
        }
        if (otherBank) {
            clientDetailsText += `\n\n*⚠️ Importante: Enviar SINPE Móvil al ${companyData.selectedRestaurant.sinpe_number} a nombre de ${companyData.selectedRestaurant.sinpe_name}.*`;
        }
    } else if (isSINPE) {
        clientDetailsText += `\n\n*⚠️ Su orden está siendo procesada, en un momento le enviaremos el monto total para que pueda hacer el pago a través de SINPE Móvil y completar la orden.*`;
    }

    if (isSINPE) {
        clientDetailsText += '\n\n*Recuerda enviar el comprobante del pago a través de este chat.*';
    }
    const waLink = `${Utilities.buildWhatsAppLink(
        companyData.selectedRestaurant.whatsapp
    )}&text=${encodeURIComponent(clientDetailsText)}`;
    callback(waLink);
};

export const listToString = (list) => {
    if (!list.length) return '';
    if (list.length === 1) return list[0];

    const firsts = list.slice(0, list.length - 1);
    const last = list[list.length - 1];
    if (last.charAt(0) === 'i' || last.charAt(0) === 'I') return firsts.join(', ') + ' e ' + last;
    return firsts.join(', ') + ' y ' + last;
};

export const isExpress = (data) => {
    return data[Constants.USER.DELIVERY] === Constants.EXPRESS_OPTION;
};

export const isEatIn = (data) => {
    return data[Constants.USER.DELIVERY] === Constants.EAT_IN_OPTION;
};

export const isPickup = (data) => {
    return data[Constants.USER.DELIVERY] === Constants.PICKUP_OPTION;
};

export const isSinpe = (paymentMethod) => {
    return paymentMethod === Constants.PAYMENT.SINPE;
};
