import React from 'react';
import { useSelector } from 'react-redux';

const Card = () => {
    const { selectedRestaurant } = useSelector((state) => state.companyData);
    return (
        <>
            <p className="mb-0">Entregar la tarjeta al repartidor.</p>
            {selectedRestaurant.accepted_cards && (
                <>
                    <p className="pt-1">Tarjetas Aceptadas:</p>
                    {selectedRestaurant.accepted_cards.map((card) => (
                        <img
                            className="mr-2 accepted-card"
                            key={card}
                            src={`https://cdn.appetit.menu/images/icons/${card}.png`}
                            alt={card}
                        />
                    ))}
                </>
            )}
        </>
    );
};

export default Card;
