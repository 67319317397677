/* global google */
import QS from 'query-string';
import qs from 'qs';
import _ from 'lodash';
import Constants, { STATUS_DESC, STATUS_TEXT } from './constants';
import isMobile, { isIOS, isAndroid } from './isMobile';
import axios from 'axios';
import { startOfToday, addMinutes, isAfter, isBefore, formatDistanceToNowStrict } from 'date-fns';
import format from 'js/utils/format';
import { es } from 'date-fns/locale';

export const toMenuURL = (category) => {
    return `/${category.slug}`;
};

export const createFunctionWithTimeout = (callback, optTimeout) => {
    let called = false;
    const fn = () => {
        if (!called) {
            called = true;
            callback();
        }
    };
    setTimeout(fn, optTimeout || 1000);
    return fn;
};

export const getLocalStorageArray = (key) => {
    return getLocalStorage(key) || [];
};

export const getLocalStorageObject = (key) => {
    return getLocalStorage(key);
};

export const getLocalStorage = (key) => {
    const object = localStorage.getItem(key);
    if (!object) return null;

    try {
        return JSON.parse(object);
    } catch (error) {
        return object;
    }
};

export const setLocalStorage = (key, object) => {
    if (_.isObject(object)) {
        localStorage.setItem(key, JSON.stringify(object));
        return;
    }

    localStorage.setItem(key, object);
};

export const cleanLocalStorage = (key) => {
    if (Array.isArray(key)) {
        key.forEach((k) => localStorage.removeItem(k));
        return;
    }

    localStorage.removeItem(key);
};

export const toSlug = (text) => {
    return text.toLowerCase().replace(/\s/g, '-');
};

export const randomId = () => {
    return Math.random().toString(36).substr(2, 5).toUpperCase();
};

const formatter = new Intl.NumberFormat('es-CR', {
    style: 'currency',
    currency: 'CRC',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
});

export const toCurrency = (value) => {
    let formattedString = formatter.format(value || 0);
    formattedString = formattedString.replace(',', '.');
    formattedString = formattedString.replace(/\s+/g, ',');
    return formattedString;
};

export const getCurrentSubdomain = () => {
    const domainChunks = window.location.hostname.split('.');
    return domainChunks[0] === 'www' ? domainChunks[1] : domainChunks[0];
};

export const getSubdomain = () => {
    const subdomain = getCurrentSubdomain();
    const host = window.location.host;
    const isDefault =
        ['localhost', 'dev', 'qa'].includes(subdomain) ||
        subdomain.match(/[v,V]{1}\d{1,2}/g) ||
        host.includes('amplifyapp');
    return isDefault ? Constants.DEFAULT_SUBDOMAIN : subdomain;
};

export const getAPIURL = () => {
    const environment = getEnvironment();
    switch (environment) {
        case Constants.DEV:
            return Constants.API_URL_DEV;
        case Constants.QA:
            return Constants.API_URL_QA;
        default:
            return Constants.API_URL_PRD;
    }
};

export const getEnvironment = () => {
    const host = window.location.host;
    const isDev =
        host === 'localhost:3000' || getCurrentSubdomain() === 'dev' || host.includes('amplifyapp');

    if (isDev) {
        return Constants.DEV;
    } else if (getCurrentSubdomain() === 'qa') {
        return Constants.QA;
    } else {
        return Constants.PRODUCTION;
    }
};

export const buildImageLink = (options) => {
    if (Object.keys(options).length === 0) {
        return '';
    }
    return `${Constants.STATIC_MAP_URL}?${QS.stringify(options)}`;
};

export const serializeMarkers = (markers) => {
    return markers.map((_marker) => {
        const marker = { color: _marker.color || 'blue', ..._marker },
            markerKeysList = Object.keys(marker),
            stringsList = markerKeysList.map((markerKey) => {
                if (markerKey === 'location') {
                    return `${marker[markerKey].lat},${marker[markerKey].lng}`;
                }

                return `${markerKey}:${marker[markerKey]}`;
            });

        return stringsList.join('|');
    });
};

export const sanitizeCoords = (center) => {
    return { lat: parseFloat(center.lat), lng: parseFloat(center.lng) };
};

export const calculateDistanceBetweenPoints = (point1, point2) => {
    const restaurant = new google.maps.LatLng(point1.lat, point1.lng);
    const userLocation = point2 ? new google.maps.LatLng(point2.lat, point2.lng) : restaurant;
    return google.maps.geometry.spherical.computeDistanceBetween(restaurant, userLocation);
};

export const getDefaultCoordinates = (shoppingCart, companyData) => {
    if (isMobile()) {
        return shoppingCart[Constants.USER.ADDRESS_COORDS] || null;
    } else {
        return (
            shoppingCart[Constants.USER.ADDRESS_COORDS] ||
            sanitizeCoords(companyData.selectedRestaurant.address.point)
        );
    }
};

export const buildSinpeSms = (id, total, bank, sinpe_number) => {
    const unformattedNumber = sinpe_number.includes('-')
        ? sinpe_number.replace('-', '')
        : sinpe_number;
    const detail = `Orden ${id}`;
    const subdomain = getSubdomain();
    const demoText = subdomain === Constants.DEFAULT_SUBDOMAIN ? 'Demo: ' : '';
    const body = `${demoText}PASE ${total} ${unformattedNumber} ${detail}`;
    const bankNumber = bank;
    const encodedBody = encodeURIComponent(body);
    if (isIOS()) {
        return `sms:${bankNumber}&body=${encodedBody}`;
    } else if (isAndroid()) {
        return `sms:${bankNumber}?body=${encodedBody}`;
    }

    return '/';
};

export const buildImageUrl = (url) => {
    return `${Constants.CDN_URL_PROD}${url}`;
};

export const filteredAndSortedCategories = (menu = {}) => {
    const validStatus = [Constants.ITEM_STATUS.available, Constants.ITEM_STATUS.sold_out];
    return (menu.categories || [])
        .filter((cat) => cat.items.length && cat.items.some((i) => validStatus.includes(i.status)))
        .sort((a, b) => a.sort_order - b.sort_order);
};

export const sinpeShortenLink = (sinpeLink) => {
    const body = {
        long_url: sinpeLink
    };
    return new Promise((resolve, reject) => {
        axios
            .post(`${Constants.BITLY_SHORTEN_URL}`, body, {
                headers: {
                    Authorization: Constants.BITLY_TOKEN,
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => resolve(response.data))
            .catch((err) => reject(body.long_url));
    });
};

export const isSinpeRedirect = () => {
    const segment = window.location.pathname.split('/')[1];
    return segment === 'sinpe-payment';
};

export const extractSinpeParameters = () => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    return {
        amount: params.get('amount'),
        bank: `+${params.get('bank').trim()}`,
        phone: params.get('phone'),
        order: params.get('order'),
        isMobile: isMobile()
    };
};

export const buildWhatsAppLink = (number) => {
    return `https://api.whatsapp.com/send/?phone=${number}`;
};

export const categoryToHash = (category) => {
    return `#${category.slug}`;
};

export const sortFares = (fares) => {
    let ordered = {};

    _.each(
        _.sortBy(_.keys(fares), [
            function (fare) {
                const splitFare = fare.split('-');
                if (splitFare.length === 2) {
                    return parseFloat(splitFare[1]);
                } else if (fare.includes('>')) {
                    return parseFloat(fare.replace('>', ''));
                } else if (fare.includes('^')) {
                    return parseFloat(fare.replace('^', ''));
                }

                return 0;
            }
        ]),
        (fare) => (ordered[fare] = fares[fare])
    );

    return ordered;
};

export const isModifierItemChecked = (modifierItem) =>
    (_.has(modifierItem, 'checked') && !!modifierItem.checked) ||
    (!_.has(modifierItem, 'checked') && !!modifierItem.is_default);

export const getAppSubdomain = () => {
    let subdomain = getSubdomain();
    if (getEnvironment() !== Constants.PRODUCTION) {
        const qsParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        subdomain = qsParams.test_company || subdomain;
    }

    return subdomain;
};

export const parseOpenHours = (restaurant) => {
    const { open_hours } = restaurant;
    if (!open_hours) return;
    return _.map(open_hours, (hour) => ({
        ...hour,
        days_of_week: _.map(hour.days_of_week, _.parseInt),
        begin_minute: _.parseInt(hour.begin_minute),
        end_minute: _.parseInt(hour.end_minute)
    }));
};

export const shouldHideStatusBar = (selectedRestaurant) => {
    const isOnline = isRestaurantOnline(selectedRestaurant);
    const isOpen = isRestaurantOpen(selectedRestaurant);
    const isAboutToClose = isRestaurantAboutToClose(selectedRestaurant);

    return isOnline && isOpen && !isAboutToClose;
};

export const statusBarBg = (selectedRestaurant) => {
    if (isRestaurantHidden(selectedRestaurant)) {
        return 'danger';
    }

    return 'warning';
};

export const statusBarTitle = (restaurant) => {
    if (isRestaurantOffline(restaurant) || isRestaurantHidden(restaurant)) {
        return isRestaurantOffline(restaurant)
            ? STATUS_TEXT[STATUS_DESC.OFFLINE]
            : STATUS_TEXT[STATUS_DESC.HIDDEN];
    }

    if (isRestaurantAboutToClose(restaurant)) {
        const beginingOfToday = startOfToday();
        const schedule = getMatchSchedule(restaurant);
        if (!schedule) return 'El restaurante está próximo a cerrar';
        return STATUS_TEXT.about.replace(
            '%t',
            formatDistanceToNowStrict(addMinutes(beginingOfToday, schedule.end_minute), {
                locale: es,
                unit: 'minute',
                roundingMethod: 'ceil'
            })
        );
    }

    return STATUS_TEXT.closed;
};

export const isRestaurantOpen = (restaurant) => {
    return !restaurant.open_hours || !!getMatchSchedule(restaurant);
};

export const isRestaurantAboutToClose = (restaurant) => {
    const beginingOfToday = startOfToday();
    const currentDate = new Date();
    const schedule = getMatchSchedule(restaurant);
    if (!schedule) return false;
    const startDate = addMinutes(beginingOfToday, schedule.end_minute - 30);
    const endDate = addMinutes(beginingOfToday, schedule.end_minute);
    return isAfter(currentDate, startDate) && isBefore(currentDate, endDate);
};

export const getMatchSchedule = (restaurant) => {
    const schedules = getTodaySchedules(restaurant);
    const currentDate = new Date();
    const beginingOfToday = startOfToday();
    if (!schedules.length) return;
    return schedules.find((schedule) => {
        const startDate = addMinutes(beginingOfToday, schedule.begin_minute);
        const endDate = addMinutes(beginingOfToday, schedule.end_minute);

        return isAfter(currentDate, startDate) && isBefore(currentDate, endDate);
    });
};

export const getTodaySchedules = (restaurant) => {
    const { open_hours } = restaurant;
    const currentDate = new Date();
    const dayOfWeek = _.parseInt(format(currentDate, 'i'));
    return _.filter(open_hours, (schedule) => schedule.days_of_week.includes(dayOfWeek));
};

export const isRestaurantOnline = (restaurant) => {
    return !restaurant.status || restaurant.status === STATUS_DESC.ONLINE;
};

export const isRestaurantOffline = (restaurant) => {
    return restaurant.status && restaurant.status === STATUS_DESC.OFFLINE;
};

export const isRestaurantHidden = (restaurant) => {
    return restaurant.status && restaurant.status === STATUS_DESC.HIDDEN;
};

export const isRestaurantAvailable = (restaurant) => {
    return isRestaurantOnline(restaurant) && isRestaurantOpen(restaurant);
};
