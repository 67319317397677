import { createReducer } from '@reduxjs/toolkit';
import initialState from '../../initializers/initialState';
import * as orderActions from './actions';

const orderModal = createReducer(initialState.orderModal, {
    [orderActions.showPlaceOrderModal]: (state, action) => ({
        ...state,
        orderModalOpen: action.payload.orderModalOpen
    }),
    [orderActions.setContactErrors]: (state, action) => ({
        ...state,
        contactFormErrors: {
            ...action.payload
        }
    }),
    [orderActions.cleanContactErrors]: (state, action) => ({
        ...state,
        contactFormErrors: {}
    }),
    [orderActions.setActiveKey]: (state, action) => ({
        ...state,
        activeKey: action.payload
    })
});

export default orderModal;
