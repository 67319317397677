import React, { useState } from 'react';
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { switchRestaurant } from 'js/stores/companyData/actions';

const SelectRestaurantModal = () => {
    const { restaurants, selectedRestaurant } = useSelector((state) => state.companyData);
    const [pickedRestaurant, setPickedRestaurant] = useState(selectedRestaurant);
    const dispatch = useDispatch();

    const handleSelect = (restaurantId) => {
        const rest = restaurants.find((r) => r.id === restaurantId);
        setPickedRestaurant(rest);
    };

    const handleContinue = (e) => {
        e.preventDefault();
        dispatch(switchRestaurant(pickedRestaurant));
    };

    return (
        <>
            <h2 className="title-rest-select">
                Seleccione un restaurante para realizar el pedido:
            </h2>
            <DropdownButton
                className="rest-select"
                title={pickedRestaurant.name}
                onSelect={handleSelect}
            >
                {restaurants
                    .filter((r) => r.id !== pickedRestaurant.id)
                    .map((restaurant) => (
                        <Dropdown.Item key={restaurant.id} eventKey={restaurant.id}>
                            {restaurant.name}
                        </Dropdown.Item>
                    ))}
            </DropdownButton>
            <Modal.Footer className="rest-btn">
                <Button variant="success" onClick={handleContinue}>
                    Continuar
                </Button>
            </Modal.Footer>
        </>
    );
};

export default SelectRestaurantModal;
