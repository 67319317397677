import React from 'react';

const BackIcon = () => (
    <svg width="36px" height="22px" viewBox="0 0 36 22" version="1.1">
        <title>Back</title>
        <g id="Back" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="Back-ico"
                transform="translate(-163.000000, -209.000000)"
                stroke="#FFFFFF"
                strokeWidth="3"
            >
                <g id="Back-icon" transform="translate(165.000000, 201.500000)">
                    <g id="icon-back" transform="translate(0.000000, 9.000000)">
                        <polyline
                            id="ico-back"
                            points="9.5234375 0.285644531 0 9.78086898 8.42761123 19.4628906"
                        ></polyline>
                        <path
                            d="M1.44824219,9.87426758 L32.4287109,9.87426758"
                            id="Line-2"
                            strokeLinecap="square"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default BackIcon;
