import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import useScrollableContext from '../hooks/useScrollableContext';

const NavigatorItem = ({ id, hash, title }) => {
    const { clickHandler } = useScrollableContext();
    return (
        <Nav.Item as="li">
            <Nav.Link id={id} href={hash} onClick={clickHandler}>
                {title}
            </Nav.Link>
        </Nav.Item>
    );
};

NavigatorItem.propTypes = {
    id: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default NavigatorItem;
