import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import FieldControl from './FieldControl';

const Field = ({
    label,
    type,
    name,
    value,
    error,
    handler,
    clickHandler,
    options,
    usePlaceholder,
    disabled,
    readonly,
    additionalClass
}) => {
    return (
        <Form.Group
            controlId={`order-form-${name}`}
            className={`${name} ${additionalClass} ${type === 'hidden' ? 'hide' : ''}`}
        >
            {label && !usePlaceholder && <Form.Label>{`${label}:`}</Form.Label>}
            <FieldControl
                type={type}
                name={name}
                value={value}
                handler={handler}
                clickHandler={clickHandler}
                options={options}
                placeholder={label}
                disabled={disabled}
                readonly={readonly}
            />
            {error && <Form.Text className="text-muted error">{error}</Form.Text>}
        </Form.Group>
    );
};

Field.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.string,
    handler: PropTypes.func,
    clickHandler: PropTypes.func,
    options: PropTypes.any,
    usePlaceholder: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    additionalClass: PropTypes.string
};

export default Field;
