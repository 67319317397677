import React from 'react';

import '../../../../styles/base/loading.scss';

const Redirect = () => {
    return (
        <div>
            <React.Fragment>
                <h5 className="center">Redireccionando a WhatsApp...</h5>
                <figure className="icon-wa">
                    <img src="/images/wa.gif" alt="WhatsApp" title="WhatsApp" />
                </figure>
                <p className="note-wa">
                    Recordá enviar el comprobante de la transferencia por WhatsApp.
                </p>
            </React.Fragment>
        </div>
    );
};

export default Redirect;
