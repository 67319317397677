import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShoppingCart from './components/ShoppingCart';
import * as orderActions from '../../stores/order/actions';
import * as modalActions from '../../stores/modal/actions';
import * as shoppingCartActions from '../../stores/shoppingCart/actions';
import * as itemActions from '../../stores/items/actions';
import * as appActions from '../../stores/app/actions';

const mapStateToProps = (state, ownProps) => {
    return {
        shoppingCartOpen: state.app.shoppingCartOpen,
        ...state.shoppingCart,
        ...state.itemModal,
        ...ownProps
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        Object.assign({}, orderActions, shoppingCartActions, modalActions, itemActions, appActions),
        dispatch
    )
});

const ShoppingCartContainer = connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);

export default ShoppingCartContainer;
