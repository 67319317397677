import React from 'react';
import PropTypes from 'prop-types';
import ShoppingCartItem from './ShoppingCartItem';

const ShoppingCartList = ({
    items,
    actions: { showAppModal, singleItem, handleOpenShoppingCart }
}) => (
    <nav id="main-nav">
        <div className="header-order">
            <h3>Su pedido</h3>
            <button className="btn-back">x</button>
        </div>
        <ul className="option-set clearfix" data-option-key="filter">
            {items.map((item, index) => (
                <ShoppingCartItem
                    key={`${item.id}-${index}`}
                    item={item}
                    displayModalWithItem={showAppModal}
                    singleItem={singleItem}
                    handleOpenShoppingCart={handleOpenShoppingCart}
                />
            ))}
        </ul>
    </nav>
);

ShoppingCartList.propTypes = {
    items: PropTypes.array.isRequired,
    actions: PropTypes.object
};

export default ShoppingCartList;
