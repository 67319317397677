import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import ItemDetails from 'js/components/itemDetails';
import Constants from 'js/utils/constants';

const SingleItem = ({
    actions: { singleItem, handleAddItemToCart, handleEditShoppingCartItem, setHeader }
}) => {
    const { selectedMenu: menu } = useSelector((state) => state.companyData);
    const { categorySlug, itemId } = useParams();
    const { action } = useSelector((state) => state.itemDetails);
    const history = useHistory();
    const lastLocation = useLastLocation();
    const category = menu && menu.categories.find((category) => category.slug === categorySlug);
    const item = category && category.items.find((item) => item.id === itemId);
    const backRoute = (item && `/${categorySlug}`) || '/';

    useEffect(() => {
        if (!item) return;

        const headerPayload = {
            title: item.name
        };
        if (!!item.image) {
            headerPayload.background_img = item.image;
        }
        setHeader(headerPayload);
        if (!action) {
            singleItem({
                item,
                category,
                action: Constants.ADD,
                backRoute: backRoute
            });
        }
    }, [item, category, setHeader, singleItem, action, backRoute]);

    const navigateBack = () => {
        if (lastLocation) {
            return history.go(-1);
        }

        history.replace('/');
    };

    const addItemToCartAndGoBack = (item) => {
        handleAddItemToCart(item);
        navigateBack();
    };
    const editCartItemAndGoBack = (item) => {
        handleEditShoppingCartItem(item);
        navigateBack();
    };
    const addToCartHandler =
        action === Constants.ADD ? addItemToCartAndGoBack : editCartItemAndGoBack;

    return item ? <ItemDetails handler={addToCartHandler} /> : <Redirect to={backRoute} />;
};
SingleItem.propTypes = {
    selectedRestaurant: PropTypes.object,
    action: PropTypes.string,
    backRoute: PropTypes.string,
    loading: PropTypes.bool,
    selectedItem: PropTypes.object,
    actions: PropTypes.object
};

export default SingleItem;
