import React from 'react';
import PropTypes from 'prop-types';
import ContentContainer from './Content';
import Header from './header';
import Footer from './footer';

const DigitalMenuLayout = ({ children }) => {
    return (
        <ContentContainer contentClass="digital-menu">
            <Header />
            <div id="shadow"></div>
            <div className="wrapper wrapper-digitalmenu">{children}</div>
            <Footer />
        </ContentContainer>
    );
};

DigitalMenuLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default DigitalMenuLayout;
