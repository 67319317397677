import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { shouldHideStatusBar, statusBarBg, statusBarTitle } from 'js/utils/utilities';

const RestaurantStatus = () => {
    const { selectedRestaurant } = useSelector((state) => state.companyData);
    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(statusBarTitle(selectedRestaurant));
        const interval = setInterval(() => {
            if (!shouldHideStatusBar(selectedRestaurant)) {
                setTitle(statusBarTitle(selectedRestaurant));
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [selectedRestaurant]);

    if (shouldHideStatusBar(selectedRestaurant)) {
        return null;
    }

    return (
        <Navbar
            fixed="top"
            collapseOnSelect
            expand="lg"
            bg={statusBarBg(selectedRestaurant)}
            variant="light"
            className="status-restaurant"
        >
            <Container>
                <Nav className="m-auto">
                    <p className="text-dark">{title}</p>
                </Nav>
            </Container>
        </Navbar>
    );
};

export default RestaurantStatus;
