import Card from './Card';
import Sinpe from './Sinpe';
import Cash from './Cash';
import Constants from 'js/utils/constants';

import { paymentMethodsFactory } from './PaymentMethods';

const classes = {
    [Constants.PAYMENT.CARD]: Card,
    [Constants.PAYMENT.SINPE]: Sinpe,
    [Constants.PAYMENT.CASH]: Cash
};

paymentMethodsFactory(classes);
