import React from 'react';
import PropTypes from 'prop-types';
import Field from './Field';
import FieldControl from './FieldControl';
import * as FormUtils from 'js/utils/formUtils';

const DynamicForm = ({
    data,
    formKeys,
    handler,
    clickHandler,
    errors,
    additionalData,
    usePlaceholder,
    useFields,
    useIcons
}) => {
    const form = formKeys.map((key, index) => {
        const disabled = FormUtils.getFormDisabled(key, data);
        const readonly = FormUtils.getFormReadonly(key);
        const hidden = FormUtils.getFormHidden(key, data);
        if (!useFields) {
            return (
                <div
                    className={`inputs-container ${disabled ? 'disabled' : ''} ${
                        hidden ? 'hide' : ''
                    }`}
                    key={index}
                >
                    {useIcons && (
                        <i className={`fa fa-${FormUtils.getFormIcon(key, data[key])}`}></i>
                    )}
                    <Field
                        label={FormUtils.getFormLabels(key)}
                        type={FormUtils.getFormType(key)}
                        name={key}
                        value={FormUtils.getFormValue(key, data)}
                        error={errors[key]}
                        handler={handler}
                        clickHandler={clickHandler}
                        usePlaceholder={usePlaceholder}
                        additionalClass={FormUtils.getFieldClass(key)}
                        readonly={readonly}
                        disabled={disabled}
                        options={FormUtils.getFormOptions(key, additionalData)}
                    />
                </div>
            );
        }

        return (
            <FieldControl
                key={index}
                type={FormUtils.getFormType(key)}
                name={key}
                value={data[key]}
                placeholder={FormUtils.getFormLabels(key)}
                handler={handler}
                clickHandler={clickHandler}
                readonly={readonly}
                disabled={disabled}
                options={FormUtils.getFormOptions(key, additionalData)}
            />
        );
    });
    return <React.Fragment>{form}</React.Fragment>;
};

DynamicForm.propTypes = {
    data: PropTypes.object,
    formKeys: PropTypes.array,
    handler: PropTypes.func,
    clickHandler: PropTypes.func,
    errors: PropTypes.object,
    additionalData: PropTypes.object,
    usePlaceholder: PropTypes.bool,
    useFields: PropTypes.bool,
    useIcons: PropTypes.bool
};

export default DynamicForm;
