import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SingleItem from './components/SingleItem';
import * as headerActions from 'js/stores/header/actions';
import * as itemActions from 'js/stores/items/actions';
import * as shoppingCartActions from 'js/stores/shoppingCart/actions';

export default connect(null, (dispatch) => ({
    actions: bindActionCreators(
        { ...shoppingCartActions, ...itemActions, ...headerActions },
        dispatch
    )
}))(SingleItem);
