/* global google */
import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import * as MapUtils from 'js/utils/mapUtils';

const Map = ({ center, fallbackCoords, handleCoords }) => {
    const mapRef = useRef(null);
    const initMap = useCallback(() => {
        // init map
        mapRef.current = MapUtils.initMap(center || fallbackCoords);
        // add center marker
        MapUtils.addDefaultMarker(mapRef.current);
        // add location button
        MapUtils.addUserLocationButton(mapRef.current, fallbackCoords, handleCoords);
        // add drag end listener
        google.maps.event.addListener(mapRef.current, 'dragend', () => {
            MapUtils.deactivateLocationButton();
            handleCoords(mapRef.current.getCenter().toJSON());
        });
    }, [center, fallbackCoords, handleCoords]);

    useEffect(() => {
        if (!mapRef.current) {
            initMap();
        } else {
            mapRef.current.setCenter(center);
        }
    }, [initMap, center, mapRef]);

    return <div id="map" className="map" />;
};

Map.propTypes = {
    center: PropTypes.object,
    fallbackCoords: PropTypes.object,
    handleCoords: PropTypes.func
};

export default Map;
