import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Navbar } from 'react-bootstrap';

const Navigator = ({ children, defaultActiveKey }) => {
    return (
        <Navbar className="row no-gutters categories-nav" sticky="top">
            <div className="container">
                <Nav defaultActiveKey={defaultActiveKey} as="ul">
                    {children}
                </Nav>
            </div>
        </Navbar>
    );
};

Navigator.propTypes = {
    children: PropTypes.node.isRequired,
    defaultActiveKey: PropTypes.string.isRequired
};

export default Navigator;
