import React from 'react';
import PropTypes from 'prop-types';
import Constants from 'js/utils/constants';
import { toCurrency } from 'js/utils/utilities';
import { Button } from 'react-bootstrap';

const AddToCartButton = ({ item, action, handler }) => {
    const isDisabled = item.status === Constants.ITEM_STATUS.sold_out || !item.isValid;
    return (
        <Button
            size="lg"
            block
            variant={item.status === Constants.ITEM_STATUS.sold_out ? 'outline-danger' : 'success'}
            disabled={isDisabled}
            onClick={(e) => handler(item)}
        >
            {action === Constants.ADD ? (
                <span className="btn-label">
                    {item.status === Constants.ITEM_STATUS.sold_out
                        ? 'Agotado'
                        : `Agregar ${item.quantity} al pedido`}
                </span>
            ) : (
                <span className="btn-label">Guardar</span>
            )}
            {item.status === Constants.ITEM_STATUS.available && (
                <span className="btn-price">{toCurrency(item.subtotal)}</span>
            )}
        </Button>
    );
};

AddToCartButton.propTypes = {
    item: PropTypes.object,
    action: PropTypes.string,
    handler: PropTypes.func
};

export default AddToCartButton;
