import { createReducer } from '@reduxjs/toolkit';
import initialState from '../../initializers/initialState';
import * as itemViewActions from './actions';

const itemView = createReducer(initialState.itemView, {
    [itemViewActions.selectItem]: (state, action) => ({
        ...state,
        selectedItem: action.payload
    })
});

export default itemView;
