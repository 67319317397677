import { createReducer } from '@reduxjs/toolkit';
import initialState from '../../initializers/initialState';
import * as appActions from './actions';

const app = createReducer(initialState.app, {
    [appActions.openSideMenu]: (state, action) => ({
        ...state,
        sideMenuOpen: action.payload
    }),
    [appActions.openShoppingCart]: (state, action) => ({
        ...state,
        shoppingCartOpen: action.payload
    }),
    [appActions.appLoading]: (state, action) => ({
        ...state,
        loading: action.payload
    })
});

export default app;
