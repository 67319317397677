import _ from 'lodash';
import Constants from './constants';
import * as Utilities from './utilities';
import {
    createCircle,
    zoneContainsLocation,
    distanceBetweenCircleAndLocation,
    distanceFromUserToCircleEdge
} from './mapUtils';

export const calculateCartTotal = (cart, companyData) => {
    const cartSubtotal = calculateCartSubtotal(cart);
    return (
        cartSubtotal +
        getLocationDeliveryFee(cart, companyData, cartSubtotal) +
        calculateOtherCharges(cart, companyData)
    );
};

export const calculateCartSubtotal = (cart) =>
    cart.items.reduce((subTotal, item) => subTotal + +item.subtotal, 0);

export const calculateItemSubtotal = (item) => {
    let subtotal = +item.price;
    if (item.modifiers) {
        subtotal = item.modifiers.reduce(
            (acc, modifier) => acc + calculateModifierSubtotal(modifier),
            subtotal
        );
    }
    return subtotal * item.quantity;
};

// {:payment_methods=>["card"], :delivery_methods=>["express", "pickup"], :amount=>500, :description=>""}
export const calculateOtherCharges = (cart, companyData) => {
    const { other_charges: otherCharges } = companyData.settings;
    if (!otherCharges) return 0;
    if (!shouldApplyOtherCharges(cart, companyData)) return 0;

    return +otherCharges.amount;
};

export const shouldApplyOtherCharges = (cart, companyData) => {
    const { other_charges: otherCharges } = companyData.settings;
    if (!otherCharges) return false;
    const { payment_methods: paymentMethods, delivery_methods: deliveryMethods } = otherCharges;
    return (
        paymentMethods.includes(cart.userPaymentMethod) &&
        deliveryMethods.includes(cart.userDelivery)
    );
};

export const getOtherChargesLegend = (cart, companyData) => {
    const { other_charges: otherCharges } = companyData.settings;
    if (!otherCharges) return '';
    if (!shouldApplyOtherCharges(cart, companyData)) return '';
    return otherCharges.description;
};

export const calculateModifierSubtotal = (modifier) =>
    modifier.items &&
    modifier.items
        .filter((item) => Utilities.isModifierItemChecked(item))
        .reduce((itemAcc, item) => itemAcc + +item.price, 0);

export const getLocationDeliveryFee = (cart, companyData, cartSubtotal) => {
    // return 0 if calculate express setting is enabled
    if (companyData.settings.disable_calculate_express) return 0;
    // return 0 if delivery option is not express
    if (cart[Constants.USER.DELIVERY] !== Constants.EXPRESS_OPTION) return 0;
    const userLocation = cart[Constants.USER.ADDRESS_COORDS];
    // return 0 if user location is not available
    if (!userLocation) return 0;

    const { selectedRestaurant } = companyData;
    if (selectedRestaurant.delivery_zones) {
        let matchAreas = [];
        const { delivery_zones: deliveryZones = [] } = selectedRestaurant;
        const userCircles = deliveryZones.filter(
            (zone) => zone.type === 'circle' && zoneContainsLocation(zone, userLocation)
        );
        const userPolygons = deliveryZones.filter(
            (zone) => zone.type === 'polygon' && zoneContainsLocation(zone, userLocation)
        );

        // check if user location is in a circle then we should reduce to the closer one
        if (userCircles.length) {
            const closerArea = userCircles.reduce((closerCircle, currentCircle) => {
                const { shape } = currentCircle;
                if (!shape || !shape.radius) return closerCircle;
                if (!closerCircle) return currentCircle;
                const currentCircleDistance = distanceFromUserToCircleEdge(
                    createCircle(shape),
                    userLocation
                );
                const closerCircleDistance = distanceFromUserToCircleEdge(
                    createCircle(closerCircle.shape),
                    userLocation
                );
                // return current circle if its distance is less than the closer circle or
                // current circle distance is equal to closer circle and its delivery fee is greater than the closer circle fee
                return currentCircleDistance < closerCircleDistance ||
                    (currentCircleDistance === closerCircleDistance &&
                        getZoneDeliveryFee(currentCircle, deliveryZones, userLocation) <
                            getZoneDeliveryFee(closerCircle, deliveryZones, userLocation))
                    ? currentCircle
                    : closerCircle;
            }, null);
            matchAreas.push(closerArea);
        }

        if (userPolygons.length) {
            const matchPolygon = userPolygons.reduce((matchingPolygon, currentPolygon) => {
                const { shape } = currentPolygon;
                if (!shape || !shape.path) return matchingPolygon;
                if (!matchingPolygon) return currentPolygon;

                return getZoneDeliveryFee(currentPolygon) < getZoneDeliveryFee(matchingPolygon)
                    ? currentPolygon
                    : matchingPolygon;
            }, null);

            matchAreas.push(matchPolygon);
        }

        return matchAreas.reduce((matchFee, currentArea) => {
            const areaFee = getZoneDeliveryFee(currentArea, deliveryZones, userLocation);
            if (!matchFee) return areaFee;
            return areaFee < matchFee ? areaFee : matchFee;
        }, 0);
    }

    // TODO: delete this once we have all businesses using shapes
    let fareValue = 0;
    const restaurantLocation = selectedRestaurant.address.point;
    let distance =
        cart[Constants.USER.ADDRESS_DISTANCE] ||
        Utilities.calculateDistanceBetweenPoints(restaurantLocation, userLocation);
    const deliveryPricingObj = selectedRestaurant.delivery_config || {};
    const fares = deliveryPricingObj.delivery_fees || {};
    const sortedFares = Utilities.sortFares(fares);
    Object.keys(sortedFares).some((fare, index) => {
        const splitFare = fare.split('-');
        // if the distance is between a range
        if (splitFare.length === 2) {
            const minDistance = parseFloat(splitFare[0]);
            const maxDistance = parseFloat(splitFare[1]);
            if (distance > minDistance && distance <= maxDistance) {
                const fareObject = sortedFares[fare];
                if (_.isObject(fareObject)) {
                    const { minAmount, minAmountFare, fare } = fareObject;
                    if (cartSubtotal >= +minAmount) {
                        fareValue = +minAmountFare;
                        return true;
                    }

                    fareValue = +fare;
                    return true;
                }

                fareValue = parseInt(fareObject);
                return true;
            }
            // if the distance is greater than a min distance
        } else if (fare.includes('>')) {
            const minDistance = parseFloat(fare.replace('>', ''));
            if (distance > minDistance && distance <= deliveryPricingObj.max_distance) {
                fareValue = parseInt(sortedFares[fare]);
                return true;
            }
            // if the fare should increment a specific amount when the distance is greater than a specific distance
        } else if (fare.includes('^')) {
            const minDistance = parseFloat(fare.replace('^', ''));
            if (distance > minDistance && distance <= deliveryPricingObj.max_distance) {
                const distanceDifference = distance - minDistance;
                const differenceFare =
                    Math.ceil(distanceDifference / 1000) * parseInt(sortedFares[fare]);
                const lastFare = sortedFares[Object.keys(sortedFares)[index - 1]] || 0;
                fareValue = parseInt(lastFare) + differenceFare;
            }
        }

        return false;
    });

    return fareValue;
};

export const getZoneDeliveryFee = (zone, deliveryZones = [], userLocation = null) => {
    let deliveryFee = +zone.delivery_fee;
    if (zone.type === 'circle' && zone.charge_per_distance) {
        let minDistance = 0;
        const distance = distanceBetweenCircleAndLocation(createCircle(zone.shape), userLocation);
        const baseZone = deliveryZones.find((z) => z.zone_id === zone.base_zone);
        minDistance = baseZone ? +baseZone.shape.radius : minDistance;
        const distanceDifference = distance - minDistance;
        const differenceFare =
            Math.ceil(distanceDifference / 1000) * parseInt(zone.delivery_fee, 10);
        deliveryFee = ((baseZone && +baseZone.delivery_fee) || 0) + differenceFare;
    }

    return deliveryFee;
};

export const isInBounds = (companyData, userLocation) => {
    const restaurantLocation = companyData.selectedRestaurant.address.point;
    const distance = Utilities.calculateDistanceBetweenPoints(restaurantLocation, userLocation);
    const deliveryPricingObj = companyData.selectedRestaurant.delivery_config;
    return distance <= deliveryPricingObj.maxDistance;
};

export const getCartLSKey = (restaurantId) => `${Constants.SHOPPING_CART_LS}_${restaurantId}`;
export const getUserDefaultsLSKey = (restaurantId) =>
    `${Constants.USER_DEFAULTS_LS}_${restaurantId}`;

export const updateShoppingCartLS = (cart, restaurantId) => {
    const cartLsKey = getCartLSKey(restaurantId);
    Utilities.setLocalStorage(cartLsKey, cart);
    updateUserDefaultsLS(cart, restaurantId);
};

export const updateUserDefaultsLS = (userDefaultsObject, restaurantId) => {
    const userDefaultsLsKey = getUserDefaultsLSKey(restaurantId);
    let userDefaults = {};
    Object.keys(Constants.USER).forEach((key) => {
        const mappedKey = Constants.USER[key];
        userDefaults[mappedKey] = userDefaultsObject[mappedKey]
            ? userDefaultsObject[mappedKey]
            : '';
    });
    Utilities.setLocalStorage(userDefaultsLsKey, userDefaults);
};

export const loadShoppingCartLS = (restaurantId) => {
    const lsKey = getCartLSKey(restaurantId);
    let cart = Utilities.getLocalStorageObject(lsKey);
    // FALLBACK: if no cart then check if there is an old version of the LS Cart
    if (!cart) {
        cart = Utilities.getLocalStorageObject(Constants.SHOPPING_CART_LS);
        // if fallback available then migrate it
        if (cart) {
            Utilities.cleanLocalStorage(Constants.SHOPPING_CART_LS);
            updateShoppingCartLS(cart, restaurantId);
        }
    }
    return cart;
};

export const loadUserDefaultsLS = (restaurantId) => {
    const lsKey = getUserDefaultsLSKey(restaurantId);
    let userDefaults = Utilities.getLocalStorageObject(lsKey);
    // FALLBACK: if no user defaults then check if there is an old version of the LS User defaults
    if (!userDefaults) {
        userDefaults = Utilities.getLocalStorageObject(Constants.USER_DEFAULTS_LS);
        // if fallback available then migrate it
        if (userDefaults) {
            Utilities.cleanLocalStorage(Constants.USER_DEFAULTS_LS);
            updateUserDefaultsLS(userDefaults, restaurantId);
        }
    }
    return userDefaults || {};
};

export const cleanShoppingCartLS = (restaurantId) => {
    Utilities.cleanLocalStorage(getCartLSKey(restaurantId));
};

export const phoneMask = (input) => {
    let strippedValue = input.replace(/[^0-9]/g, '');
    if (strippedValue.length > 4) {
        strippedValue = [strippedValue.slice(0, 4), '-', strippedValue.slice(4, 8)].join('');
    }
    return strippedValue;
};

export const getInitialCart = (restaurantId) => {
    const shoppingCart = loadShoppingCartLS(restaurantId);
    if (shoppingCart) {
        return shoppingCart;
    }

    return {
        total: 0,
        subtotal: 0,
        deliveryFee: 0,
        items: []
    };
};

export const getInitialUserDefaults = (selectedRestaurant) => {
    const userDefaults = loadUserDefaultsLS(selectedRestaurant.id);
    if (Object.keys(userDefaults).length) {
        if (!userDefaults.userAddressCoords) {
            userDefaults.userAddress = '';
        }
        return userDefaults;
    }

    return {
        [Constants.USER.NAME]: '',
        [Constants.USER.PHONE]: '',
        [Constants.USER.PAYMENT_METHOD]: '',
        [Constants.USER.DELIVERY]:
            (selectedRestaurant.delivery_options && selectedRestaurant.delivery_options[0]) ||
            'express',
        [Constants.USER.LOCATION]: null,
        [Constants.USER.ADDRESS]: '',
        [Constants.USER.ADDRESS_REFERENCES]: '',
        [Constants.USER.ADDRESS_COORDS]: null,
        [Constants.USER.SINPE_BANK]: ''
    };
};

export const getUserRestaurantName = () => {
    return Utilities.getLocalStorage('name');
};

export const setUserRestaurantName = (restaurant) => {
    return Utilities.setLocalStorage('name', _.snakeCase(restaurant.name));
};

export const getUserRestaurant = () => {
    return Utilities.getLocalStorage(Constants.USER_RESTAURANT_LS);
};

export const setUserRestaurant = (restaurant) => {
    setUserRestaurantName(restaurant);
    return Utilities.setLocalStorage(Constants.USER_RESTAURANT_LS, restaurant.id);
};

export const cleanUpUserRestaurant = () => {
    Utilities.cleanLocalStorage(Constants.USER_RESTAURANT_LS);
};

export const isModifierValid = (modifier) => {
    const quantity = modifier.quantity;
    const checkedItems = modifier.items.filter((item) => Utilities.isModifierItemChecked(item))
        .length;
    return quantity === checkedItems;
};

export const isItemValid = (item) =>
    !item.modifiers.some((modifier) => !modifier.isValid && modifier.required);
