import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toMenuURL, buildImageUrl } from 'js/utils/utilities';

const Card = ({ category }) => {
    return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <div className="margin-wrapper content-category">
                <Link to={toMenuURL(category)}>
                    <figure className="images">
                        <img
                            width="632"
                            height="419"
                            src={buildImageUrl(category.image)}
                            alt="menu-item"
                        />
                    </figure>
                    <div className="covering-image centered">
                        <div className="info-box-content">
                            <div className="parent">
                                <div className="child">
                                    <figure className="icon">
                                        <img src={buildImageUrl(category.icon)} alt="" />
                                    </figure>
                                    <h2>{category.name}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

Card.propTypes = {
    category: PropTypes.object,
    selectedRestaurant: PropTypes.object
};

export default Card;
