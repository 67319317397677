import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ItemsList from './components/ItemsList';
import * as headerActions from 'js/stores/header/actions';
import * as modalActions from 'js/stores/modal/actions';
import * as itemActions from 'js/stores/items/actions';
import * as companyDataActions from 'js/stores/companyData/actions';

export default connect(null, (dispatch) => ({
    actions: bindActionCreators(
        { ...modalActions, ...itemActions, ...headerActions, ...companyDataActions },
        dispatch
    )
}))(ItemsList);
