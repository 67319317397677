import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './reducers';
import preloadedState from './initialState';
import { googleAnalytics } from '../utils/reactGAMiddlewares';

export const history = createBrowserHistory();

const middleware = [
    ...getDefaultMiddleware({ immutableCheck: false }),
    routerMiddleware(history),
    googleAnalytics
];

const store = configureStore({
    reducer: rootReducer(history),
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState
});

export default store;
