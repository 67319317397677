import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import Nav from './Nav';
import Logo from './Logo';
import BackIcon from './BackIcon';
import SelectRestaurantButton from './SelectRestaurantButton';
import RestaurantStatus from './RestaurantStatus';
import FilterPlanComponent from 'js/components/plan/FilterPlanComponent';

const Header = ({ rightButton }) => {
    const { settings } = useSelector((state) => state.companyData);
    const { title, background_img } = useSelector((state) => state.header);
    const history = useHistory();
    const lastLocation = useLastLocation();
    const companyLogo = settings.branding && settings.branding.header.logo;

    const navigateBack = () => {
        if (lastLocation) {
            return history.goBack();
        }

        history.push('/');
    };

    return (
        <>
            <Nav
                bgImage={settings.supports_menu_photos ? background_img : null}
                settings={settings}
            >
                {title && (
                    <div className="content-header-title">
                        <button className="btn-back" onClick={navigateBack}>
                            <BackIcon />
                        </button>
                        <span className="header-title">{title}</span>
                    </div>
                )}
                <Logo className={!title ? '' : 'hide'} companyLogo={companyLogo} />
                {rightButton}
                {!title && <SelectRestaurantButton />}
            </Nav>
            {!title && <FilterPlanComponent component={RestaurantStatus} />}
        </>
    );
};

Header.defaultProps = {
    rightButton: null
};

Header.propTypes = {
    rightButton: PropTypes.object
};

export default Header;
