import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import _ from 'lodash';
import Item from './Item';
import * as Tracker from 'js/utils/tracker';
import Constants from 'js/utils/constants';

import 'styles/menu/menu_items.scss';

const ItemsList = ({ showModal, actions: { showAppModal, singleItem, setHeader } }) => {
    const { selectedMenu: menu, settings } = useSelector((state) => state.companyData);
    const [rendered, setRendered] = useState(false);
    const { categorySlug } = useParams();
    const categoryItem = menu && menu.categories.find((category) => category.slug === categorySlug);
    const availableItems =
        (categoryItem &&
            categoryItem.items.filter(
                (item) => item.status !== Constants.ITEM_STATUS.unavailable
            )) ||
        [];

    useEffect(() => {
        if (!categoryItem) return;

        setHeader({
            title: categoryItem.name,
            background_img: null
        });
    }, [setHeader, categoryItem]);

    const itemsList = useMemo(
        () =>
            _.orderBy(availableItems, ['name', (i) => +i.price]).map((item, index) => (
                <Item
                    key={item.id}
                    item={item}
                    menu={menu}
                    category={categoryItem}
                    showAppModal={showAppModal}
                    singleItem={singleItem}
                    indexInList={index}
                    showPhoto={settings.supports_menu_photos}
                />
            )),
        [
            availableItems,
            categoryItem,
            menu,
            settings.supports_menu_photos,
            showAppModal,
            singleItem
        ]
    );

    if (!categoryItem || !availableItems.length) {
        return <Redirect to="/" />;
    }

    // register products impressions
    if (!showModal && !rendered) {
        setRendered(true);
        Tracker.addImpressions(availableItems || []);
    }

    return (
        <div className="item-list-view">
            <section className="section">
                <div className="container">
                    <div className="row no-gutters products-list">{itemsList}</div>
                </div>
            </section>
        </div>
    );
};

ItemsList.propTypes = {
    showModal: PropTypes.bool,
    actions: PropTypes.object
};

export default ItemsList;
