import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import initialState from '../../initializers/initialState';
import * as companyDataActions from './actions';

const companyData = createReducer(initialState.companyData, {
    [companyDataActions.loadCompanyData]: (state, action) => ({
        ...state,
        ...action.payload
    }),
    [companyDataActions.showSelectRestaurantModal]: (state, action) => ({
        ...state,
        showSelectRestaurantModal: action.payload.showSelectRestaurantModal
    }),
    [companyDataActions.selectDefaultRestaurantAndMenu]: (state, { payload = {} }) => {
        const { selectedRestaurant, selectedMenu } = state;
        const { selectedRestaurant: restaurantPayload, menuId } = payload;

        let restaurant = restaurantPayload || { ...selectedRestaurant };

        let restaurantHasMenus =
                !_.isEmpty(restaurant) && restaurant.menus && !!restaurant.menus.length,
            menu = _.isObject(selectedMenu) ? { ...selectedMenu } : {},
            selectedMenuIsEmpty = _.isEmpty(menu),
            menuNotMatchesRestaurant = menu.business_id !== restaurant.id;

        if (restaurantHasMenus && menuId) {
            menu = { ..._.find(restaurant.menus, ['id', menuId]) };
        } else if (restaurantHasMenus && (selectedMenuIsEmpty || menuNotMatchesRestaurant)) {
            menu = { ...restaurant.menus[0] };
        }

        const selectedMenuCategories = _.filter(restaurant.categories, (c) =>
            (c.menus || []).includes(menu.slug)
        );
        const menuCategories = _.map(selectedMenuCategories, (category) => {
            const categoryItems = _.filter(restaurant.items, (item) =>
                (item.categories || []).map((c) => c.category_id).includes(category.slug)
            );
            return { ...category, items: categoryItems };
        });
        menu = { ...menu, categories: menuCategories };

        return { ...state, selectedRestaurant: restaurant, selectedMenu: menu };
    }
});

export default companyData;
