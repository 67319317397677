import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const LoadingLayout = ({ animation }) => (
    <div className="container vh-100">
        <div className="row h-100 justify-content-center align-items-center">
            <Spinner animation={animation} role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    </div>
);

LoadingLayout.defaultProps = {
    animation: 'border'
};

LoadingLayout.propTypes = {
    animation: PropTypes.string
};

export default LoadingLayout;
