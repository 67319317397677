import React from 'react';
import PropTypes from 'prop-types';
import * as Tracker from 'js/utils/tracker';
import { toCurrency } from 'js/utils/utilities';
import isMobile from 'js/utils/isMobile';
import { useHistory } from 'react-router-dom';
import Constants from 'js/utils/constants';

import placeholderImage from 'images/placeholder-img-menu.png';

import CurrentPlan from 'js/components/plan/CurrentPlan';

const Item = ({ item, showAppModal, singleItem, indexInList, category, showPhoto }) => {
    const history = useHistory();
    const openItem = (e) => {
        Tracker.registerItemClick(item, indexInList);
        singleItem({
            item,
            action: Constants.ADD,
            category
        });
        history.push(`/${category.slug}/${item.id}`);
    };

    const openModal = () => {
        Tracker.registerItemClick(item, indexInList);
        showAppModal(true, Constants.ADD_TO_CART_MODAL, {
            item,
            action: Constants.ADD,
            category,
            showModal: true
        });
    };

    const hiddenInCurrentPlan = CurrentPlan.hideElement('ItemDetails');
    const handler = !hiddenInCurrentPlan ? (isMobile() ? openItem : openModal) : () => {};

    return (
        <div className="col-lg-6 col-md-12 col-sm-12">
            <div
                className={`menu-item ${
                    item.status === Constants.ITEM_STATUS.sold_out
                        ? Constants.ITEM_STATUS.sold_out
                        : ''
                }`}
                onClick={handler}
            >
                {showPhoto && (
                    <div className="row show-img">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-4 img-items">
                            <img src={item.image || placeholderImage} alt="" />
                        </div>

                        <div className="col-lg-9 col-md-9 col-sm-9 col-8 content-items">
                            <h5>{item.name}</h5>
                            <span className="price">{toCurrency(item.price)}</span>
                            {item.status === Constants.ITEM_STATUS.sold_out && (
                                <span className="status-label">Agotado</span>
                            )}
                        </div>
                    </div>
                )}
                {!showPhoto && (
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 desc-item">
                        <h5>{item.name}</h5>{' '}
                        {item.status === Constants.ITEM_STATUS.sold_out && (
                            <span className="status-label">Agotado</span>
                        )}
                        <p>{item.description}</p>
                        <span className="price">{toCurrency(item.price)}</span>
                        {!hiddenInCurrentPlan && <span className="view-more">Ver detalles</span>}
                    </div>
                )}
            </div>
        </div>
    );
};

Item.propTypes = {
    item: PropTypes.object,
    showAppModal: PropTypes.func,
    singleItem: PropTypes.func,
    indexInList: PropTypes.number,
    menu: PropTypes.object,
    category: PropTypes.object,
    showPhoto: PropTypes.bool
};

export default Item;
