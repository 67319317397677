import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SocialList from './components/SocialList';
import { buildWhatsAppLink } from 'js/utils/utilities';
import { phoneMask } from 'js/utils/cartUtils';

const Footer = () => {
    const {
        facebook,
        instagram,
        selectedRestaurant: { phone, address, whatsapp }
    } = useSelector((state) => state.companyData);
    const social = { facebook, instagram, whatsapp: buildWhatsAppLink(whatsapp) };
    return (
        <footer>
            <div>
                <SocialList social={social} />
                <p>
                    <i className="fa fa-phone"></i> {phoneMask(phone)}
                    <i className="fa fa-map-marker"></i> {address.text}
                </p>
                <p className="footer-menu">
                    <Link to="/appetit-terms-of-service" rel="noopener noreferrer">
                        Términos y condiciones
                    </Link>
                    <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSf3UdSdYrpEjpYk1Bk33vLcVER-EYdOf7w0AVVQ577OpwXK-w/viewform"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Comentarios
                    </a>
                </p>
            </div>
            <div className="copyright">
                <p>Copyright © {new Date().getFullYear()}. Todos los derechos reservados.</p>
                <p>
                    Hecho con <i className="fa fa-heart"></i>en San Carlos por{' '}
                    <a className="mailto" href="mailto:info@appetit.menu">
                        Appetit
                    </a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
