import React from 'react';
import PropTypes from 'prop-types';
import { useAccordionToggle } from 'react-bootstrap';
import OrderCheckoutItem from './OrderCheckoutItem';
import PlaceOrderButton from './PlaceOrderButton';
import OrderContact from './OrderContact';
import { toCurrency } from 'js/utils/utilities';
import * as FormUtils from 'js/utils/formUtils';
import { isExpress } from 'js/utils/orderUtils';
import { shouldApplyOtherCharges, getOtherChargesLegend } from 'js/utils/cartUtils';

const OrderDetails = (props) => {
    const {
        shoppingCart,
        companyData,
        actions: { setActiveKey, handleValidateForm }
    } = props;
    const { selectedRestaurant } = companyData;
    const showSubtotalOnly =
        isExpress(shoppingCart) && companyData.settings.disable_calculate_express;
    const toggleShippingAddress = useAccordionToggle('1', () => setActiveKey('1'));
    const toggleProcessOrder = useAccordionToggle('4', () => setActiveKey('4'));
    const proceedToShippingAddress = () => {
        toggleShippingAddress();
    };
    const placeOrder = () => {
        if (FormUtils.formIsValid(shoppingCart, companyData)) {
            // hide modal close button
            document.getElementsByClassName('modal-header')[0].classList.add('invisible');
            toggleProcessOrder();
        } else {
            handleValidateForm();
        }
    };
    const itemList = shoppingCart.items.map((item, index) => (
        <OrderCheckoutItem key={`${index}-${item.id}`} item={item} />
    ));
    return (
        <>
            <h5>
                Revisar Orden{' '}
                {companyData.restaurants.length > 1 ? `- ${selectedRestaurant.name}` : ''}
            </h5>
            <OrderContact {...props} handleAddress={proceedToShippingAddress} />
            <div className="container">
                <div className="row no-gutters add-to-cart">
                    <div className="col-12">
                        <div>
                            <h5 className="marg-top">Ítems en esta orden</h5>
                            <div className="col-12 cont-list-items">
                                <ul>{itemList}</ul>
                            </div>
                        </div>
                        <div className="col-12 nopadding">
                            <ul className="totals">
                                {showSubtotalOnly ? (
                                    <li>
                                        <h5 className="main-total-price">
                                            <span className="total-label">Subtotal:</span>
                                            <span>{toCurrency(shoppingCart.total)}</span>
                                            <p className="pt-1 text-left text-muted">
                                                Monto total será proporcionado a través de WhatsApp
                                                una vez enviada la orden y el monto del express haya
                                                sido calculado por parte del comercio.
                                            </p>
                                        </h5>
                                    </li>
                                ) : (
                                    <>
                                        {isExpress(shoppingCart) && (
                                            <>
                                                <li>
                                                    <span className="total-label">Subtotal:</span>
                                                    <span>{toCurrency(shoppingCart.subtotal)}</span>
                                                </li>
                                                <li>
                                                    <span className="total-label">Express:</span>
                                                    <span>
                                                        {toCurrency(shoppingCart.deliveryFee)}
                                                    </span>
                                                </li>
                                            </>
                                        )}
                                        {shouldApplyOtherCharges(shoppingCart, companyData) && (
                                            <li>
                                                <span className="total-label">Otros cargos:</span>
                                                <span>{toCurrency(shoppingCart.otherCharges)}</span>
                                                <p className="pt-1 text-left text-muted">
                                                    {getOtherChargesLegend(
                                                        shoppingCart,
                                                        companyData
                                                    )}
                                                </p>
                                            </li>
                                        )}
                                        <li>
                                            <h5 className="main-total-price">
                                                <span className="total-label">Total:</span>
                                                <span>{toCurrency(shoppingCart.total)}</span>
                                            </h5>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                        <div className="btn-container-whatsapp">
                            <div className="col-12">
                                <PlaceOrderButton handlePlaceOrder={placeOrder} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

OrderDetails.propTypes = {
    shoppingCart: PropTypes.object,
    companyData: PropTypes.object,
    actions: PropTypes.object
};

export default OrderDetails;
