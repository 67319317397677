import $ from 'jquery';

export const calculateTopPosForId = (id) => {
    const section = document.getElementById(id);
    return section.getBoundingClientRect().top + window.pageYOffset;
};

export const scrollToSection = (id, cb = () => {}) => {
    const topPos = calculateTopPosForId(id);
    const duration = 300;
    scrollToPos({ scrollTop: topPos }, duration, cb);
};

export const scrollToPos = (options, duration, callback = () => {}) => {
    const fixedOffset = options.scrollTop.toFixed();
    const onScroll = () => {
        if (window.pageYOffset.toFixed() === fixedOffset) {
            window.removeEventListener('scroll', onScroll);
            callback();
        }
    };

    window.addEventListener('scroll', onScroll);
    onScroll();
    $('html, body').animate(options, { duration, queue: false });
};

export const scrollNavbar = (target, duration = 100, callback = () => {}) => {
    const targetEl = $(target),
        navBarContainer = $('.navbar-nav').parent(),
        elementWidth = targetEl.width() / 2,
        elementPos = targetEl.position().left + elementWidth,
        parentPos = navBarContainer.scrollLeft(),
        parentWidth = navBarContainer.width(),
        leftPos = elementPos + parentPos - parentWidth / 2;

    navBarContainer.animate(
        { scrollLeft: leftPos },
        { duration, queue: false, complete: callback }
    );
};

export const isSelected = (sections, onFinish) => {
    const scrolledTo = $(window).scrollTop();
    for (let i = 0; i < sections.current.length; i++) {
        const section = $(sections.current[i]);
        const targetTop = getTargetTop(section);
        const targetBottom = getTargetBottom(section);
        if (scrolledTo > targetTop && scrolledTo < targetBottom) {
            sections.current.removeClass('active');
            section.addClass('active');
            onFinish(section);
        }
    }
};

export const animateMenuNavegation = (target, sections, onFinish) => {
    sections.current.removeClass('active');
    $(target).addClass('active');
    scrollToSection(target.hash.replace('#', ''), onFinish);
    scrollNavbar(target);
};

const getTargetTop = (elem) => {
    const id = elem.attr('href');
    const offset = 60;
    return $(id).offset().top - offset;
};

const getTargetBottom = (elem) => {
    const id = elem.attr('href').replace('#', '');
    const offset = 250;
    return $(`#section-${id}`).offset().top + $(`#section-${id}`).height() - offset;
};
