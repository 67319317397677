import { useSelector } from 'react-redux';

class CurrentPlan {
    static hiddenComponents = {
        plus: [],
        lite: ['RestaurantStatus', 'ShoppingCartButton', 'ItemDetails'],
        free: ['RestaurantStatus']
    };
    static includes = Array.prototype.includes;

    static isFree() {
        const companyData = useSelector((state) => state.companyData);
        return companyData && companyData.plan === 'free';
    }

    static getCurrentPlan = () => {
        const companyData = useSelector((state) => state.companyData);
        return companyData ? companyData.plan : 'plus';
    };
    static hideElement = (element) => {
        return this.includes.call(this.hiddenComponents[this.getCurrentPlan()] || [], element);
    };
}

export default CurrentPlan;
