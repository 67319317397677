import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Constants from '../../utils/constants';

const Terms = () => {
    const [state, setState] = useState();

    useEffect(() => {
        axios
            .get(`${Constants.CDN_URL_PROD}/terms.html`)
            .then((response) => {
                setState(response.data);
            })
            .catch((error) => console.log(`Error loading data: ${error}`));
    }, []);

    return (
        <div>
            <section className="section terms-cond">
                <div className="container">
                    <h1>Términos y condiciones</h1>
                    <div
                        className="row no-gutters"
                        dangerouslySetInnerHTML={{ __html: state }}
                    ></div>
                </div>
            </section>
        </div>
    );
};

export default Terms;
