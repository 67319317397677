import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { isRestaurantAvailable } from 'js/utils/utilities';

const PlaceOrderButton = ({ handlePlaceOrder }) => {
    const { selectedRestaurant } = useSelector((state) => state.companyData);
    const [isDisabled, setIsDisabled] = useState(false);

    const placeOrder = () => {
        if (isRestaurantAvailable(selectedRestaurant)) {
            handlePlaceOrder();
        }
    };

    useEffect(() => {
        setIsDisabled(!isRestaurantAvailable(selectedRestaurant));
        const interval = setInterval(() => {
            if (!isRestaurantAvailable(selectedRestaurant)) {
                setIsDisabled(true);
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [selectedRestaurant]);

    return (
        <>
            {isDisabled && (
                <p className="text-muted">
                    El restaurante no se encuentra recibiendo pedidos en este momento. Por favor
                    inténtelo más tarde.
                </p>
            )}
            <Button
                className="btn-lg btn-block"
                disabled={isDisabled}
                variant="success"
                onClick={() => placeOrder()}
            >
                Ordenar por WhatsApp
            </Button>
        </>
    );
};

PlaceOrderButton.propTypes = {
    handlePlaceOrder: PropTypes.func.isRequired
};

export default PlaceOrderButton;
