import axios from 'axios';
import { getAPIURL } from '../../utils/utilities';

class appApi {
    static getCompanyData = (subdomain = 'demo') => {
        return axios
            .get(`${getAPIURL()}/companies/${subdomain}`)
            .then((response) => response.data)
            .catch((error) => console.log(`Error loading data: ${error}`));
    };

    static getRestaurantData = (subdomain, restaurantId) => {
        return axios
            .get(`${getAPIURL()}/companies/${subdomain}/businesses/${restaurantId}`)
            .then((response) => response.data)
            .catch((error) => console.log(`Error loading data: ${error}`));
    };
}

export default appApi;
