import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const FieldControl = ({
    type,
    value,
    name,
    options,
    handler,
    clickHandler,
    placeholder,
    readonly,
    disabled,
    label,
    fieldValue
}) => {
    const emptyHandler = () => {};
    const defaults = {
        type,
        name,
        disabled,
        value: value || '',
        readOnly: readonly,
        onChange: handler || emptyHandler,
        onClick: clickHandler || emptyHandler
    };
    switch (type) {
        case 'radio':
            if (options) {
                return options.map((option, index) => (
                    <Form.Check
                        id={option}
                        key={index}
                        checked={value === option}
                        label={option}
                        {...defaults}
                        value={option}
                    />
                ));
            } else {
                return (
                    <Form.Check
                        id={label}
                        checked={value === label}
                        label={label}
                        {...defaults}
                        value={fieldValue}
                    />
                );
            }
        case 'checkbox':
            return <Form.Check id={name} label={label} value={label} onChange={handler} />;
        case 'textarea':
            return <Form.Control rows={3} as={type} value={value} name={name} onChange={handler} />;
        case 'select':
            return (
                <Form.Control as="select" value={value} name={name} onChange={handler}>
                    {Object.keys(options).map((option, index) => (
                        <option key={index} value={option}>
                            {options[option]}
                        </option>
                    ))}
                </Form.Control>
            );
        case 'label':
            return (
                <div
                    className={'form-label'}
                    onClick={() =>
                        clickHandler ? clickHandler({ target: { name } }) : emptyHandler()
                    }
                >
                    {value}
                </div>
            );
        default:
            return <Form.Control {...defaults} placeholder={placeholder} />;
    }
};

FieldControl.propTypes = {
    type: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string,
    options: PropTypes.any,
    handler: PropTypes.func,
    clickHandler: PropTypes.func,
    placeholder: PropTypes.string,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    fieldValue: PropTypes.string
};

export default FieldControl;
