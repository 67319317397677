import React from 'react';
import PropTypes from 'prop-types';
import Constants from '../../utils/constants';
import * as Utils from '../../utils/utilities';
import isMobile from '../../utils/isMobile';

const StaticMap = ({ center, isDisabled, onClick }) => {
    const mapOptions = {
        zoom: 18,
        size: isMobile() ? '282x292' : '438x272',
        key: Constants.GOOGLE_API_KEY,
        markers: Utils.serializeMarkers([{ location: center, color: 'red' }])
    };
    const imageLink = Utils.buildImageLink(mapOptions);
    return (
        <img
            className={isDisabled ? 'is-disabled' : ''}
            src={imageLink}
            alt={'Mapa'}
            onClick={onClick}
        />
    );
};

StaticMap.propTypes = {
    center: PropTypes.object,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func
};

export default StaticMap;
