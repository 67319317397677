import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ id, title, children, customClass }) => {
    return (
        <div>
            <section className={`section ${customClass}`} id={`section-${id}`}>
                <div className="container">
                    <div className="row no-gutters">
                        <h3 id={id}>{title}</h3>
                        {children}
                    </div>
                </div>
            </section>
        </div>
    );
};

Section.defaultProps = {
    customClass: ''
};

Section.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    customClass: PropTypes.string
};

export default Section;
