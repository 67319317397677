export default {
    app: {
        loading: true,
        sideMenuOpen: false,
        shoppingCartOpen: false
    },
    modal: {
        open: false,
        modalProps: {},
        componentType: '',
        componentProps: {}
    },
    itemDetails: {
        action: null,
        showModal: false,
        showingItem: {
            title: '',
            description: '',
            price: 0,
            image: '',
            quantity: 1,
            subtotal: 0,
            orderNotes: '',
            modifiers: []
        }
    },
    orderModal: {
        orderModalOpen: false,
        activeKey: '0',
        proceedToCheckout: false,
        contactFormErrors: {}
    },
    itemView: {
        selectedItem: {},
        loading: false
    },
    addressForm: {
        addressFormErrors: {}
    },
    shoppingCart: {
        items: []
    },
    companyData: {
        restaurants: [],
        selectedRestaurant: {},
        selectedMenu: {},
        settings: {}
    },
    header: {
        title: '',
        logo_img: '',
        background_img: ''
    }
};
