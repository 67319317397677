import { connectRouter } from 'connected-react-router';
import app from '../stores/app/reducer';
import itemDetails from '../stores/items/reducer';
import orderModal from '../stores/order/reducer';
import shoppingCart from '../stores/shoppingCart/reducer';
import modal from '../stores/modal/reducer';
import companyData from '../stores/companyData/reducer';
import addressForm from '../stores/addressForm/reducer';
import itemView from '../stores/itemView/reducer';
import header from '../stores/header/reducer';

const rootReducer = (history) => ({
    router: connectRouter(history),
    app,
    itemDetails,
    orderModal,
    shoppingCart,
    modal,
    companyData,
    addressForm,
    itemView,
    header
});

export default rootReducer;
