import { createReducer } from '@reduxjs/toolkit';
import initialState from '../../initializers/initialState';
import * as addressFormActions from './actions';

const addressForm = createReducer(initialState.addressForm, {
    [addressFormActions.setAddressErrors]: (state, action) => ({
        ...state,
        addressFormErrors: {
            ...action.payload
        }
    }),
    [addressFormActions.cleanAddressErrors]: (state, action) => ({
        ...state,
        addressFormErrors: {}
    })
});

export default addressForm;
