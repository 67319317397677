import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrderModal from './components/OrderModal';
import * as orderActions from '../../stores/order/actions';
import * as cartActions from '../../stores/shoppingCart/actions';

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.orderModal,
        shoppingCart: state.shoppingCart,
        companyData: state.companyData,
        ...ownProps
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Object.assign({}, orderActions, cartActions), dispatch)
});

const PlaceOrderModal = connect(mapStateToProps, mapDispatchToProps)(OrderModal);

export default PlaceOrderModal;
