import React from 'react';
import PropTypes from 'prop-types';
import { Card, Collapse } from 'react-bootstrap';
import FieldControl from 'js/components/common/FieldControl';
import Constants from 'js/utils/constants';

const CollapsedCard = ({ paymentMethod, value, children, companyData, handler }) => {
    const classNames = paymentMethod === value ? 'active-header' : '';
    return (
        <Card>
            <Card.Header className={classNames}>
                <FieldControl
                    type={'radio'}
                    name={Constants.USER.PAYMENT_METHOD}
                    label={Constants.PAYMENT_MAPPER[paymentMethod]}
                    value={value}
                    fieldValue={paymentMethod}
                    handler={handler}
                />
            </Card.Header>
            <Collapse in={paymentMethod === value}>
                <Card.Body>{children}</Card.Body>
            </Collapse>
        </Card>
    );
};

CollapsedCard.propTypes = {
    paymentMethod: PropTypes.string,
    value: PropTypes.string,
    children: PropTypes.node,
    companyData: PropTypes.object,
    handler: PropTypes.func
};

export default CollapsedCard;
